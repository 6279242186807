import styled from "styled-components";
import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { offerSent } from "../redux/ride/rideAction";
import { useEffect } from "react";

const PartnerMessageView = ({ item, length, index, biddingAmounts }) => {
  const [open, setOpen] = React.useState(false);
  const [offerAmount, setOfferAmount] = React.useState(0);

  const { offerSending, bidding } = useSelector((state) => state.rideReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(false);
  }, [bidding]);

  const oferSendingClick = () => {
    if (offerAmount < 1) {
      alert("Please enter a valid amount");
      return;
    }

    if (offerAmount >= item.biddingAmount) {
      alert("Sorry, you can't send more than or same the current offer");
      return;
    }

    if (!offerSending) {
      dispatch(offerSent(item.biddingId, offerAmount));
    }
  };

  const offerSendDialog = () => {
    return (
      <Dialog open={open} onClose={(e) => setOpen(false)}>
        <DialogTitle>Send Offer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span style={{ color: "black" }}>
              Partner Last Offer Amount: {item.biddingAmount}Tk.
            </span>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter Your Offer Amount"
            type="number"
            fullWidth
            variant="standard"
            autoComplete="off"
            onChange={(e) => setOfferAmount(e.target.value)}
            defaultValue={offerAmount}
          />
        </DialogContent>
        <DialogActions>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{ backgroundColor: "#e48922", color: "white" }}
              onClick={oferSendingClick}
            >
              {" "}
              {offerSending ? "sending..." : "Send Offer"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  const getStringForPartnerShow = () => {
    if (index > 0) {
      if (item.biddingAmount === biddingAmounts[index - 1].biddingAmount) {
        return "Partner Accepted Your Offer";
      } else {
        return "Partner Again Offered";
      }
    } else {
      return "Partner Offered You";
    }
  };

  const isPartnerAccepted = () => {
    if (
      index > 0 &&
      item.biddingAmount === biddingAmounts[index - 1].biddingAmount
    )
      return true;
    return false;
  };

  return (
    <div>
      <Wrapper>
        <Avatar
          alt={bidding?.partner?.name}
          src={bidding?.partner?.img}
          sx={{ width: 40, height: 40 }}
        />
        <div>
          <MessageSession>
            <div>
              <div style={{ lineHeight: "13px" }}>
                <span className="message">{getStringForPartnerShow()}</span>
                <p>
                  <strong>{item.biddingAmount}Tk.</strong>
                </p>
              </div>
              {length - 1 === index &&
              item.offerSendBy === "partner" &&
              !isPartnerAccepted() ? (
                <div
                  onClick={(e) => setOpen(true)}
                  style={{
                    paddingTop: "12px",
                    fontSize: "13px",
                    cursor: "pointer",
                    // backgroundColor: "#e48922",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    color: "#e48922",
                    fontWeight: "bold",
                  }}
                >
                  <span>Send another offer</span>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </MessageSession>
        </div>

        {offerSendDialog()}
      </Wrapper>
    </div>
  );
};

// const partnerChatBg = "#F79520";
const partnerChatBg = "#e0e0e0";
const textColor = "#000000";

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const MessageSession = styled.div`
  margin-left: 12px;
  margin-top: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 18px;
  padding-right: 18px;
  background: ${partnerChatBg};
  border-radius: 0px 11px 11px 11px;
  position: relative;
  color: ${textColor};
  &:after {
    content: " ";
    position: absolute;
    right: 100%;
    top: 0;
    border: solid transparent;
    border-right-color: ${partnerChatBg};
    border-top-color: ${partnerChatBg};
    border-width: 5px;
  }
  p {
    margin: 0px;
    padding-top: 3px;
  }

  .message {
    font-weight: 600;
    position: relative;
    font-size: 12px;
  }
`;

export default PartnerMessageView;
