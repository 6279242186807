import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Card,
  Divider,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import DriverInfo from "./DriverInfo";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const RideDetailsDetailView = ({
  bidding,
  expendView,
  handleClickOpen,
  isEpendable,
}) => {
  return (
    <Accordion expanded={isEpendable} className="removeShadow pnone__">
      <AccordionSummary
        className="removeShadow pnone__"
        style={{
          backgroundColor: "#f4f3f9",
          border: "none",
        }}
        onClick={expendView}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          Show Full Details
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: "#f4f3f9",
        }}
      >
        <Card>
          <DriverInfo
            img={bidding?.driver?.img}
            name={bidding?.driver?.name}
            phone={bidding?.status === "accepted" ? bidding?.driver?.phone : ""}
            handleClickOpen={handleClickOpen}
          />

          <Divider />

          <ListItem button>
            <Avatar
              alt={""}
              src={bidding.car.carFrontImage}
              sx={{ width: 60, height: 60 }}
            />
            <ListItemText
              primary={
                bidding.car.car_type.name + " - " + bidding.car.car_brand.name
              }
              secondary={
                <div
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="black"
                >
                  {bidding.car.car_model.name + " - "}
                  {bidding.car.year.year + " - "}
                  {bidding.car.color.name}
                </div>
              }
              style={{ paddingLeft: "10px" }}
            />
          </ListItem>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

export default RideDetailsDetailView;
