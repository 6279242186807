import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import {
  Card,
  CardContent,
  Divider,
  ListItemText,
  Typography,
} from "@mui/material";
import { QuicarSmLogo } from "./QuicarLogo";
const NotificationItem = ({ item }) => {
  const navigate = useNavigate();

  const clickHandler = () => {
    navigate(`/notification/${item?.id}`);
  };

  return (
    <>
      <ListItem
        style={{
          backgroundColor: item?.isSeen ? "#ffffff" : "#f5f5f5",
        }}
        onClick={clickHandler}
        button
      >
        <ListItemText
          style={{
            color: item?.isSeen ? "#636363" : "#0e0e0e",
            display: "flex",
          }}
          primary={
            <div style={{ marginRight: "10px" }}>
              <QuicarSmLogo />
            </div>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                style={{
                  color: item?.isSeen ? "#636363" : "#0e0e0e",
                }}
              >
                <h4>{item?.title}</h4>
                <p>{item?.description.substring(0, 110)}</p>
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      {/* <Card
        style={{
          backgroundColor: item?.isSeen ? "#ffffff" : "#f5f5f5",
        }}
        onClick={clickHandler}
        className="cursor-pointer "
      >
        <CardContent>
          <ItemWrapper>
            <div className="logo_wrapper">
              <QuicarSmLogo />
            </div>
            <div className="content_wrapper">
              <h5 className="title">{item.title}</h5>
              <p className="details">
                {item?.description.substring(0, 110)}...
              </p>
            </div>
          </ItemWrapper>
        </CardContent>
      </Card> */}
      <Divider />
    </>
  );
};

const Item = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  h3 {
    font-size: 1.2rem;
    margin: 0;
    color: #131313;
  }
  span {
    font-size: 0.8rem;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  .logo_wrapper {
    width: 40px;
  }
  .content_wrapper {
    margin-left: 5px;
    flex: 1;

    .title {
      font-size: 15px;
      padding-bottom: 4px;
    }
    .details {
      font-size: 15px;
      padding-bottom: 4px;
    }
  }
`;
// dynamic style span color change item.isSeen

export default NotificationItem;
