import { CircularProgress } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageLayout from "../components/layout/PageLayout";
import { getPolicy } from "../redux/policy/policyAction";
import styled from "styled-components";
import PageLayoutLoginCheckNon from "../components/layout/PageLayoutLoginCheckNon";
const PrivacyPolicyPage = ({ title = "Policy" }) => {
  const data = useSelector((state) => state.policyReducer);
  let [searchParams, setSearchParams] = useSearchParams();
  let { type } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [type,setType] = useState(null)

  useEffect(() => {
    if (type) {
      dispatch(getPolicy(type));
    } else {
      // navigate('/main/home', {
      //     replace: true,
      // })
    }
  }, [type]);

  return (
    <PageLayoutLoginCheckNon
      title={
        type
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          ?.replace(/\b\w/g, (match) => match.toUpperCase()) ?? title
      }
      appbar={true}
    >
      {data.loading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : data[type] ? (
        <div style={{ padding: "20px" }}>
          <div dangerouslySetInnerHTML={{ __html: data[type] }}></div>
        </div>
      ) : (
        <LoaderWrapper>
          <div>No Data Found</div>
        </LoaderWrapper>
      )}
    </PageLayoutLoginCheckNon>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
`;

export default PrivacyPolicyPage;
