import React from "react";
import GlobalWrapper from "../GlobalWrapper";
import AppbarSimple from "../header/AppbarSimple";
import "../../mainopage.scss";

const PageLayout = ({
  children,
  appbar,
  title,
  rideBidding,
  notificationBadge = false,
  bgColor = "#ffffff",
  appBgColor = "#F79520",
  iconColor = "white",
  titleColor = "white",
  elevation,
  badgeIconColor,
  overflow = "auto",
}) => {
  // const navigate = useNavigate();
  // const {accessToken,user} = useSelector(state=>state.usereducer);

  // useEffect(() => {
  //     if (!accessToken || !user) {
  //         navigate('/login')
  //     }

  //     if (accessToken && user && !user.name) {
  //         navigate('/register')
  //     }
  // }, [accessToken,user]);

  return (
    <GlobalWrapper>
      <div className="main__page">
        <div className="app__wrapper">
          {appbar ? (
            <AppbarSimple
              title={title}
              rideBidding={rideBidding}
              notificationBadge={notificationBadge}
              appBgColor={appBgColor}
              iconColor={iconColor}
              titleColor={titleColor}
              elevation={elevation}
              badgeIconColor={badgeIconColor}
            />
          ) : null}
          <div
            className="content"
            style={{
              backgroundColor: bgColor,
              overflow: overflow,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </GlobalWrapper>
  );
};

export default PageLayout;
