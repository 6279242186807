import React from "react";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { Avatar, Typography } from "@mui/material";
const DriverInfo = ({ img, name, phone, handleClickOpen }) => {
  return (
    <ListItem
      style={{
        position: "relative",
      }}
      button
    >
      <Avatar alt={""} src={img} sx={{ width: 60, height: 60 }} />
      <ListItemText
        primary={name}
        // secondary={bidding.driver.phone}
        secondary={
          <div style={{ display: "flex", gap: "5px" }}>
            {/* {item?.description.substring(0, 100)} */}
            {phone && (
              <>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {phone}
                </Typography>
                <div>
                  <button onClick={(e) => window.open(`tel:${phone}`)}>
                    Call Now
                  </button>
                </div>
              </>
            )}
            {/* {" — I'll be in your neighborhood doing errands this…"} */}
          </div>
        }
        style={{ paddingLeft: "10px" }}
      />

      <button
        style={{
          position: "absolute",
          right: "0px",
          top: "0px",
          display: "flex",
        }}
        onClick={handleClickOpen}
      >
        Details
      </button>
    </ListItem>
  );
};

export default DriverInfo;
