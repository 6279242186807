import { Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import PageLayout from "../components/layout/PageLayout";
import { requestApi } from "../network/httpRequest";
import { COUPON_LIST } from "../network/urls";
import "./coupon.scss";
import toast, { Toaster } from "react-hot-toast";
const CouponPage = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    getCouponList();
  }, []);

  const getCouponList = async () => {
    const { data } = await requestApi().request(COUPON_LIST);
    console.log(data);
    if (data?.status) {
      setList(data["data"]["coupons"]);
    } else {
      alert(data.message);
    }
  };

  function copyToClipboard(refferalCode) {
    navigator.clipboard.writeText(refferalCode);
    toast("Copied to clipboard", {
      duration: 1000,
      position: "top-right",

      // Styling
      style: {},
      className: "",

      // Custom Icon
      icon: "👏",

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },

      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  }

  return (
    <PageLayout
      title="Coupon"
      appbar={true}
      notificationBadge={false}
      appBgColor="#F79520"
      bgColor="white"
      iconColor={"white"}
      titleColor="white"
      badgeIconColor="white"
      elevation={5}
    >
      <Toaster />
      {list &&
        list.map((coupon, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "5px",
                height: "70px",
                borderStyle: "dashed",
                borderWidth: "3px",
                borderColor: "#cccccc",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  height: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {coupon.name}
                  </span>
                </div>

                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "none",
                    paddingRight: "10px",
                  }}
                >
                  {coupon.code}
                </span>
              </div>

              <Button
                variant="contained"
                style={{
                  backgroundColor: "#F79520",
                }}
                onClick={() => copyToClipboard(coupon.code)}
              >
                Copy
              </Button>
            </div>
          );
        })}
    </PageLayout>
  );
};

export default CouponPage;
