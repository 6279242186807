import React, { useState, useEffect, useRef } from "react";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AlertMsg = ({ isOpen, toastMessage, closeAlert, type = "error" }) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={closeAlert}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <Alert onClose={closeAlert} severity={type} sx={{ width: "100%" }}>
        {toastMessage}
      </Alert>
    </Snackbar>
  );
};

export default AlertMsg;
