import React, { useEffect, useState } from "react";
import styledComponent from "styled-components";
import RideHistoryItem from "../../components/rideHistory/RideHistoryItem";
import {
  changeGetRideListType,
  getRideList,
} from "../../redux/ride/rideAction";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import HistoryIcon from "@mui/icons-material/History";
import PageLayout from "../../components/layout/PageLayout";
import { getFavoritePartner } from "../../redux/favoritePartner/favoritesPartnersAction";
import PartnerCard from "../../components/PartnerCard";

const FavoritePartnerPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list, loading, hasNextPage } = useSelector(
    (state) => state.favoritesPartners
  );

  useEffect(() => {
    dispatch(getFavoritePartner(true));
  }, []);

  const loadMore = () => {
    dispatch(getFavoritePartner(false));
  };

  return (
    <div>
      {/* <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <RideSelection/>
            </div> */}

      <PageLayout
        title="Favorite Partners"
        appbar={true}
        appBgColor="#F79520"
        titleColor="white"
        iconColor="white"
      >
        {list &&
          list.map((item, index) => {
            return <PartnerCard key={index} item={item} />;
          })}

        {loading && (
          <CenterDiv>
            <CircularProgress size={25} />
          </CenterDiv>
        )}

        {hasNextPage && !loading && (
          <CenterDiv>
            <Button variant="outlined" onClick={loadMore}>
              Load More
            </Button>
          </CenterDiv>
        )}

        {list && list.length < 1 && !loading && (
          <CenterVertical>
            <HistoryIcon
              style={{ width: "50px", height: "50px", fill: "grey" }}
            ></HistoryIcon>
          </CenterVertical>
        )}
      </PageLayout>
    </div>
  );
};

const CenterDiv = styledComponent.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
`;
const CenterVertical = styledComponent.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    width:100%;
    height:80vh;
    justify-content: center;
    align-items: center;
`;

export default FavoritePartnerPage;
