import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  rides: [],
  paginate: null,
  type: "send",
  nextPage: 1,
  previousPage: 1,
  currentPage: 1,
  hasNextPage: true,
  hasPreviousPage: false,
  paging: [],
  pageSize: 10,
  error: null,
  bidding: null,
  rideDetailLoading: false,
  offerSending: false,
  userPaymentConditions: [],
  offerAccepting: false,
  cancelReasons: [],
  rideCancelCutInfo: null,
  status: false,
};

const rideReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.RIDE_LIST_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.RIDE_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        rides: payload.start
          ? payload.rides
          : [...state.rides, ...payload.rides],
        error: null,
        message: null,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        nextPage: payload.paginate.metadata.page.nextPage,
        previousPage: payload.paginate.metadata.page.previousPage,
      };

    case actionTypes.RIDE_LIST_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case actionTypes.RIDE_LIST_REQUEST_TYPE_CHANGE:
      console.log(payload);
      return {
        ...state,
        loading: false,
        type: payload,
      };

    case actionTypes.SINGLE_RIDE_BIDDING_DETAIL_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        rideDetailLoading: true,
        error: null,
      };

    case actionTypes.SINGLE_RIDE_BIDDING_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        message: null,
        bidding: payload.bidding,
        userPaymentConditions: payload.userPaymentConditions,
      };

    case actionTypes.SINGLE_RIDE_BIDDING_DETAIL_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        rideDetailLoading: false,
        error: payload,
      };

    case actionTypes.BIDDING_OFFER_REQUEST_SEND:
      return {
        ...state,
        offerSending: true,
        error: null,
      };

    case actionTypes.BIDDING_OFFER_REQUEST_SUCCESS:
      return {
        ...state,
        offerSending: false,
        error: null,
        bidding: payload,
      };

    case actionTypes.BIDDING_OFFER_REQUEST_FAIL:
      return {
        ...state,
        offerSending: false,
        error: payload,
      };

    case actionTypes.PARTNER_OFFER_ACCEPT_REQUEST_SEND:
      return {
        ...state,
        offerAccepting: true,
        error: null,
      };

    case actionTypes.PARTNER_OFFER_ACCEPT_SUCCESS:
      return {
        ...state,
        offerAccepting: false,
        error: null,
        bidding: payload["biddings"],
      };

    case actionTypes.PARTNER_OFFER_ACCEPT_FAIL:
      return {
        ...state,
        offerAccepting: false,
        error: payload,
      };

    case actionTypes.GET_CANCEL_REASONS_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_CANCEL_REASONS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        cancelReasons: payload,
      };

    case actionTypes.GET_CANCEL_REASONS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case actionTypes.CANCEL_RIDE_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        error: null,
        status: false,
      };

    case actionTypes.CANCEL_RIDE_REQUEST_SUCCESS:
      const itemIndex = state.rides.findIndex(
        (item) => item.id === payload?.id
      );
      if (itemIndex !== -1) {
        state.rides[itemIndex].status = "canceled";
      }
      return {
        ...state,
        loading: false,
        error: null,
        rides: [...state.rides],
        status: true,
        message: payload.message,
      };

    case actionTypes.GET_CANCEL_RIDE_CUT_CAL_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        error: null,
      };
    case actionTypes.GET_CANCEL_RIDE_CUT_CAL_SUCCESS:
      return {
        ...state,
        loading: false,
        rideCancelCutInfo: payload,
      };

    case actionTypes.GET_CANCEL_RIDE_CUT_CAL_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case actionTypes.PARTNER_REVIEW_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        error: null,
      };
    case actionTypes.PARTNER_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        message: payload,
      };

    case actionTypes.PARTNER_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        status: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default rideReducer;
