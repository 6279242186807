import React, { useEffect, useState } from "react";
import PageLayout from "../../components/layout/PageLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestApi } from "../../network/httpRequest";
import { GET_SINGLE_RIDE } from "../../network/urls";
import styled from "styled-components";
import {
  CalenderIconSvg,
  ClockIconSvg,
  PickupAndDropOffAddressShapeSvg,
  ReturnIconSvg,
  noImage,
} from "../../helpers/staticData";
import { alertMsg, toastMsg } from "../../helpers/toastMsg";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {
  Box,
  Button,
  Modal,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import {
  cancelRideByUser,
  getCancelCutData,
  getCancelReasons,
  reviewToPartner,
} from "../../redux/ride/rideAction";
import AutocompletedInput from "../../components/AutoCompleteInput";
import AlertMsg from "../../components/AlertMsg";
import { signal } from "@preact/signals";
import { Textarea } from "@mui/joy";
// import { Textarea } from "@mui/joy";
import { Avatar } from "@mui/material";
import Tk from "../../components/Tk";
import { timeDifference } from "../../helpers/common";

const PartnerAndDriverDetails = ({ biddings }) => {
  const { driver, partner } = biddings.find(
    (item) => item.status === "accepted"
  );

  return (
    <>
      <h5 style={{ marginTop: "5px", marginLeft: "10px" }}>Parnter Info</h5>
      <div
        className="partner_driver_info"
        style={{ borderBottom: "1px solid #d8d8da" }}
      >
        <Avatar
          src={partner?.img}
          alt={partner?.name}
          sx={{ width: 60, height: 60, mr: 2 }}
        />
        <div>
          <h6 className="info">{partner?.name}</h6>
          <h6 className="info">Contact : {partner?.phone}</h6>
        </div>
      </div>
      <h5 style={{ marginTop: "5px", marginLeft: "10px" }}>Driver Info</h5>
      <div className="partner_driver_info">
        <Avatar
          src={driver?.img}
          alt={driver?.name}
          sx={{ width: 60, height: 60, mr: 2 }}
        />
        <div>
          <h6 className="info">{driver?.name}</h6>
          <h6 className="info">Contact : {driver?.phone}</h6>
          <h6 className="info">NID : {driver?.nid}</h6>
          <h6 className="info">License No : {driver?.licenseNumber}</h6>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {[
          { img: driver?.nidFontPic ?? noImage, title: "NID Font Image" },
          { img: driver?.nidBackPic ?? noImage, title: "NID Back Image" },
        ].map((item, index) => (
          <div style={{ padding: "10px 20px" }} key={index}>
            <div>
              <p>{item.title}:</p>
              <img
                src={item.img}
                alt="partner"
                className="partner_driver_img"
                loading="lazy"
                width={110}
              />
            </div>
          </div>
        ))}
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {[
          {
            img: driver?.licenseFontPic ?? noImage,
            title: "License Back Image",
          },
          {
            img: driver?.licenseBackPic ?? noImage,
            title: "License Back Image",
          },
        ].map((item, index) => (
          <div style={{ padding: "10px 20px" }} key={index}>
            <div>
              <p>{item.title}:</p>
              <img
                src={item.img}
                alt="partner"
                className="partner_driver_img"
                loading="lazy"
                width={110}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const RideDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rides, cancelReasons, status, loading, rideCancelCutInfo, message } =
    useSelector((state) => state.rideReducer);
  let [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("rideId");
  const [selectedReason, setSelectedReason] = useState(null);
  const [searchCancelReason, setSearchCancelReason] = useState("");

  const [ride, setRide] = useState(null);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [cancelRideCutAmount, setCancelRideCutAmount] = useState(0);
  const [rideAmountInfo, setRideAmountInfo] = useState(null);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const reviewData = signal({
    rating: 0,
    reviewText: "",
    rideId: id,
  });
  const [remaningBiddingTime, setRemaningBiddingTime] = useState(null);

  useEffect(() => {
    let mounted = true;
    if (id) {
      if (mounted) {
        const findRide = rides.find((item) => item.id == id);

        if (findRide) {
          setRide(findRide);
        } else {
          callApi(id);
        }
      }
    } else {
      navigate(-1);
    }

    return () => (mounted = false);
  }, [id]);

  /** call single ride api */

  const callApi = async (id) => {
    try {
      if (!id) {
        throw new Error("Invalid id");
      }

      const { data } = await requestApi().request(GET_SINGLE_RIDE, {
        params: {
          rideId: id,
        },
      });
      if (data.status) {
        setRide(data?.data);
      }
    } catch (error) {
      console.log(error.message);
      toastMsg(error.message);
      navigate(-1);
    }
  };

  // CANCEL BUTTON CLICK HANDLE

  const clickCancel = () => {
    setSelectedReason(null);
    dispatch(getCancelReasons("ride"));
    setOpenCancelModal(!openCancelModal);
    if (ride.status === "accepted") {
      dispatch(getCancelCutData(ride?.id));
    }
  };

  // Ride Cancel Amount Cut Calculation
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (typeof ride === "object") {
        setRemaningBiddingTime(
          timeDifference(new Date(ride?.onBiddingTime), new Date())
        );
        const acceptedBid = ride?.biddings.find(
          (item) => item.status === "accepted"
        );

        if (acceptedBid) {
          const bid = acceptedBid?.bidding_amounts.find(
            (item) => item.acceptedPrice
          );

          if (bid) {
            setRideAmountInfo(bid);
            if (
              typeof rideCancelCutInfo === "object" &&
              rideCancelCutInfo !== null
            ) {
              const { userCut } = rideCancelCutInfo;
              const userCutAmount = (bid?.biddingAmount * userCut) / 100;
              setCancelRideCutAmount(userCutAmount);
            }
          }
        }
      }
    }
    return () => (mounted = false);
  }, [rideCancelCutInfo, ride]);

  // CANCEL RIDE

  const cancelRide = () => {
    if (!selectedReason) {
      setToast(true);
      setToastMessage("Please select a Cancel Reason!.");
      return;
    }

    if (!ride || !ride?.id) {
      setToast(true);
      setToastMessage("Invalid Ride!.");
      return;
    }

    dispatch(
      cancelRideByUser({
        rideId: ride?.id,
        cancelReasonId: selectedReason?.id,
      })
    );
  };

  useEffect(() => {
    let mounted = true;
    if (status) {
      if (mounted) {
        setOpenCancelModal(false);
        setSelectedReason(null);

        reviewData.value = {
          rating: 0,
          reviewText: "",
          rideId: id,
        };
      }
      if (message) {
        setToast(true);
        setToastMessage(message);
      }

      setOpenReviewModal(false);
    }

    return () => (mounted = false);
  }, [status]);

  // REVIEW SENT TO PARTNER

  const changeReviewInput = (event) => {
    const { name, value } = event.target;
    reviewData.value = { ...reviewData.value, [name]: value };
  };

  const giveReviewToPartner = () => {
    const { rating, reviewText, rideId } = reviewData.value;
    if (!rating || !reviewText || !rideId) {
      setToast(true);
      setToastMessage("Invalid Request!.");
      return;
    }

    dispatch(reviewToPartner(reviewData.value));
  };

  return (
    <PageLayout
      title="Ride Details"
      appbar={true}
      appBgColor="#F79520"
      bgColor="#f4f3f9"
      iconColor={"white"}
      titleColor="white"
      elevation={5}
    >
      <Item>
        <AlertMsg
          isOpen={toast}
          toastMessage={toastMessage}
          closeAlert={() => setToast(false)}
          type={status ? "success" : ""}
        />
        <div className="wrapper">
          <div className="location__Wrapper">
            <div className="line">
              <div className="shap_line">
                <PickupAndDropOffAddressShapeSvg />
              </div>
            </div>
            <div className="address">
              <div className="pickup">
                <span className="title">যাত্রা শুরু</span>
                <span className="address__name">
                  {ride?.pickupLocation?.address}
                </span>
              </div>
              <div className="dropoff">
                <span className="title">যাত্রা শেষ</span>
                <span className="address__name">
                  {ride?.dropOffLocation?.address}
                </span>
              </div>
            </div>
          </div>

          {/* car information */}
          <div>
            <h5 style={{ textAlign: "center", marginTop: "2px" }}>
              Car Type And Trip Type
            </h5>
            <div className="car_info_wrapper">
              <div className="car__info">
                <img
                  src={ride?.carType?.image}
                  alt=""
                  width={50}
                  loading="lazy"
                />
                <span>{ride?.carType?.name}</span>
              </div>

              <div>
                <span className="title">{ride?.tripTypeText}</span>
              </div>
            </div>
          </div>

          <div className="bottom__wrapper">
            <div className={`timeing ${ride?.returnTime ? "" : "center"}`}>
              <div className="travel__date">
                <div className="icon">
                  <CalenderIconSvg />
                </div>
                <div className="time__Wrap">
                  <span className="title">Travel Date & Time</span>
                  <span className="time">{ride?.pickUpTimeText}</span>
                </div>
              </div>

              {ride?.returnTime && (
                <div className="return__date">
                  <div className="icon">
                    <ReturnIconSvg />
                  </div>
                  <div className="time__Wrap">
                    <span className="title">Return Date & Time</span>
                    <span className="time">{ride?.returnTimeText}</span>
                  </div>
                </div>
              )}
            </div>

            {ride?.status === "send" && (
              <div className="counting">
                <ClockIconSvg />
                <span style={{ marginRight: "2px" }}>On Bidding : </span>
                <span className="timeing">
                  {new Date(ride?.onBiddingTime) < new Date()
                    ? " Expried"
                    : remaningBiddingTime}
                  .
                </span>
              </div>
            )}

            <div className="status__Wrapper">
              <span className="item">Status : {ride?.statusText}. </span>
            </div>
            <div className="status__Wrapper">
              <span className="item">Total Day's : {ride?.howMuchDay}.</span>
            </div>
          </div>

          {ride?.status === "send" && (
            <div className="extra_note_wrapper">
              <h5>Extra Note</h5>
              <div className="note">{ride?.extraNote}</div>
            </div>
          )}

          {/* payment summery, driver and partner details show when ride acceptd */}

          {ride?.status === "accepted" && (
            <>
              <SummeryWrapper>
                <div className="item">
                  <h4>Total Rent: </h4>
                  <span className="amount">
                    {rideAmountInfo?.biddingAmount}TK
                  </span>
                </div>
                <div className="item">
                  <h4>Advance Payment: </h4>
                  <span className="amount">
                    {rideAmountInfo?.userAdvancePayment}TK
                  </span>
                </div>
                <div className="item">
                  <h4>Due: </h4>
                  <span className="amount">
                    {rideAmountInfo?.partnerCashReceived}TK
                  </span>
                </div>
              </SummeryWrapper>
              <PartnerAndDriverDetails biddings={ride?.biddings} />
            </>
          )}

          {["send", "accepted", "unaccepted"].includes(ride?.status) && (
            <div className="button_wrapper">
              <button className="cancel_button" onClick={clickCancel}>
                Cancel
              </button>
            </div>
          )}

          {ride?.status === "completed" && (
            <div className="button_wrapper">
              <Button
                variant="contained"
                color="success"
                onClick={() => setOpenReviewModal(!openReviewModal)}
              >
                Review
              </Button>
            </div>
          )}
        </div>
      </Item>
      {/* CANCEL RIDE MODAL */}
      <Modal
        open={openCancelModal}
        onClose={() => setOpenCancelModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AutocompletedInput
            value={selectedReason}
            onChange={(e, value) => setSelectedReason(value)}
            searchKey={searchCancelReason}
            onInputChange={(event, newInputValue) => {
              setSearchCancelReason(newInputValue);
            }}
            list={cancelReasons.length > 0 ? cancelReasons : []}
            label="Select a Cancel Reason"
            name="cancelReason"
            required={true}
          />

          <Typography mt={2}>
            {ride?.status === "accepted"
              ? `আমরা আন্তরিকভাবে দুঃখিত যে আপনি এই ট্রিপটি ক্যানসেল করতে চাচ্ছেন ।
               এই ট্রিপটি ক্যানসেল করার আগে আমাদের রিফান্ড পলিসি গুলো দেখে নিন ।
               আপনার যাত্রার সময় বাকি আছে: ২৩ ঘন্টা ।
              এই ট্রিপের জন্য অগ্রিম  দিয়েছেন : ${
                rideAmountInfo?.biddingAmount
              } টাকা ।
              এই ট্রিপটি বাতিল করলে ফেরত পাবেন: ${
                rideAmountInfo?.biddingAmount - cancelRideCutAmount
              } টাকা ।
            আপনি কি ট্রিপটি বাতিল করতে চান?`
              : "You want to cancel this ride?"}
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              color="primary"
              onClick={() => setOpenCancelModal(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="error"
              disabled={loading || !selectedReason}
              onClick={cancelRide}
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
      {/* PARTNER REVIEW MODAL */}
      <Modal
        open={openReviewModal}
        onClose={() => setOpenReviewModal(!openReviewModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>Review To Partner</Typography>
          <hr />

          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Box mb={2}>
              <Typography mb={1}>Rating</Typography>
              <Rating
                name="rating"
                value={reviewData?.rating}
                onChange={changeReviewInput}
                precision={0.5}
              />
            </Box>

            <Box mb={3}>
              <Typography mb={1}>Review</Typography>
              <Textarea
                placeholder="Type in here…"
                name="reviewText"
                minRows={2}
                maxRows={4}
                onChange={changeReviewInput}
              />
            </Box>

            <div>
              <Button
                variant="contained"
                color="success"
                sx={{ mr: 1 }}
                disabled={loading}
                onClick={giveReviewToPartner}
              >
                Send
              </Button>
              <Button
                variant="outline"
                color="error"
                onClick={() => setOpenReviewModal(false)}
                // disabled={loading || !selectedReason}
                // onClick={cancelRide}
              >
                Later
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      ;
    </PageLayout>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Item = styled.div`
  height: "100%";
  backgroundcolor: "#F1F1F1";
  .wrapper {
    padding: 10px 0px;
    .location__Wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 15px;
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 5px;
      border-bottom: 1px solid #d8d8da;

      .line {
        padding-right: 20px;
        .shap_line {
          height: 50px;
          width: 2px;
          background-color: #4a4a4c;
          position: relative;

          .top_shap {
            position: absolute;
            top: -5px;
            left: -4px;
          }
          .bottom_shap {
            position: absolute;
            bottom: -5px;
            left: -4px;
          }
        }
      }

      .address {
        .pickup {
          padding-bottom: 8px;

          border-bottom: 1px solid #bbbbbb;
          border-style: dashed;
          border-top: none;
          border-left: none;
          border-right: none;
          span {
            display: block;
          }

          .title {
            font-size: 14px;
            color: black;
            font-weight: 600;
          }
          .address__name {
          }
        }

        .dropoff {
          padding-top: 7px;
          span {
            display: block;
          }
          .title {
            font-size: 14px;
            color: black;
            font-weight: 600;
          }
        }
      }
    }

    .car_info_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;
      border-bottom: 1px solid #d8d8da;

      .car__info {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;
        text-transform: capitalize;
        font-weight: 500;
      }

      .title {
        font-weight: 500;
      }
    }

    .bottom__wrapper {
      border-bottom: 1px solid #d8d8da;
      padding-bottom: 5px;
      .timeing {
        padding: 10px 15px;

        display: flex;
        justify-content: space-between;

        &.center {
          justify-content: center;
        }

        .travel__date {
          display: flex;
          align-items: center;

          .icon {
            padding: 10px;
          }

          .time__Wrap {
            .time {
              display: block;
              font-size: 14px;
              font-weight: 600;
            }
            .title {
              display: block;
              font-size: 14px;
              color: gray;
            }
          }
        }

        .return__date {
          display: flex;
          align-items: center;

          .icon {
            padding: 10px;
          }

          .time__Wrap {
            .title {
              display: block;
              font-size: 14px;
              color: gray;
            }
            .time {
              display: block;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }

      .counting {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          padding-right: 10px;
          height: 25px;
          width: 25px;
        }

        .timeing {
          margin-right: 25px;
          padding: 0px;
          font-weight: 600;
        }
      }

      .status__Wrapper {
        display: flex;
        justify-content: center;

        .item {
          font-weight: 500;
          text-transform: capitalize;
        }
      }
    }

    .partner_driver_info {
      display: flex;
      padding: 5px 0px;
      padding-left: 30px;

      .partner_driver_img {
        margin-right: 15px;
        height: 100%;
      }
      .info {
        text-transform: capitalize;
        font-size: 15px;
      }
    }

    .button_wrapper {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      padding: 15px;

      button {
        padding: 7px 40px;
        cursor: pointer;
        &.cancel_button {
          background-color: #4b4b4b;
          color: white;
          border: #4b4b4b;
          border-radius: 5px;
          font-weight: bold;

          &:hover {
            background-color: #1f1f1f;
          }
        }
      }
    }

    .extra_note_wrapper {
      padding: 5px 15px;
      width: 100%;
      .note {
        margin-top: 5px;
        border-radius: 4px;
        background-color: lightgray;
        width: 100%;
        height: 100px;
        overflow: auto;
        padding: 2px;
      }
    }

    .driver_arrived_wrapper {
      text-align: center;
      padding: 10px 0px;

      .driver_arrived_button {
        margin-top: 10px;
      }
    }
  }

  .progressingShap {
    height: 2px;
    background-color: #f79520;
    position: absolute;
    top: 0;
    width: 78%;

    .wrap {
      position: relative;

      svg {
        position: absolute;
        top: -2px;
        right: 0;
      }
    }
  }
`;

const SummeryWrapper = styled.div`
  border-bottom: 1px solid #d8d8da;
  padding: 10px;
  .item {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 3px;
    .amount {
      margin-left: 10px;
      font-weight: 500;
    }
  }
`;

export default RideDetailsPage;
