import React from "react";
import { styled } from "@mui/material/styles";
import { Avatar, Badge, Stack } from "@mui/material";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const PartnerInformation = ({ img, name, phone }) => {
  const callPhoneNumber = () => {
    window.open(`tel:${phone}`);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "10px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Stack direction="row" spacing={2}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar alt={name} src={img} sx={{ width: 60, height: 60 }} />
        </StyledBadge>
      </Stack>
      <span>{name}</span>
      {phone && (
        <>
          <span>{phone}</span>
          <button onClick={callPhoneNumber}>Call Now </button>
        </>
      )}
    </div>
  );
};

export default PartnerInformation;
