import "./App.scss";
import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import LoginPage from "./pages/auth/LoginPage";
import MainPage from "./pages/main/MainPage";
import Notification from "./pages/notification/Notification";
import NotificationDetails from "./pages/notification/NotificationDetails";
import HomeFragment from "./pages/main/fragment/HomeFragment";
import MoreFragment from "./pages/main/fragment/MoreFragment";
import CarTypeList from "./pages/ride/CarTypeList";
import InputAddress from "./pages/ride/InputAddress";
import OverViewRideRequest from "./pages/ride/OverViewRideRequest";
import Distances from "./pages/Directions";
import NewMaps from "./components/map/NewMaps";
import VerifyOtpPage from "./pages/auth/VerifyOtpPage";
import RegistrationPage from "./pages/auth/RegistrationPage";
import BannerDetails from "./pages/banner/BannerDetails";
import SuccessPage from "./pages/ride/SuccessPage";
import RideHistoryPage from "./pages/ridehistory/RideHistoryPage";
import RideBiddingPage from "./pages/ridehistory/RideBiddingPage";

import { useDispatch } from "react-redux";
import { socketConnect } from "./redux/socket/socketAction";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import PolicyListPage from "./pages/PolicyListPage";
import TutorialPage from "./pages/more/TutorialPage";
import TutorialDetailPage from "./pages/more/TutorialDetailPage";
import QuicarPoinPage from "./pages/QuicarPoinPage";
import FavoritePartnerPage from "./pages/favoritePartner/FavoritePartnerPage";

import ProfileSettingPage from "./pages/ProfileSettingPage";
import AmountSummery from "./pages/ridehistory/AmountSummery";
import CouponPage from "./pages/CouponPage";
import UserActivity from "./pages/UserActivity";
import RideDetailsPage from "./pages/ridehistory/RideDetailsPage";
import BiddingDetailsPage from "./pages/ridehistory/BiddingDetailsPage";
import PaymentSuccessPage from "./pages/ridehistory/PaymentSuccessPage";
import Contactus from "./pages/ContactUs";

function App() {
  const dispatch = useDispatch();
  // const {socket} = useSelector(state => state.socketReducer)
  useEffect(() => {
    dispatch(socketConnect());
  }, []);

  // useEffect(() => {
  //   if(socket){
  //     socket.emit("online", {
  //       token: "b",
  //       type: "user",
  //       platform: "web",
  //     });
  //   }
  // },[socket])

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                replace
                to={`/main/${
                  localStorage.getItem("mainPage")
                    ? localStorage.getItem("mainPage")
                    : "home"
                }`}
              />
            }
          />
          <Route path="/main/*" element={<MainPage />}>
            <Route index path="home" element={<HomeFragment />} />
            {/* <Route path="ride/*" element={<RideFragment />} >
              <Route index path="" element={<RequestPage />} />
              <Route path="upcoming" element={<UpComingPage />} />
              <Route path="completed" element={<CompletedPage />} />
              <Route path="cancel" element={<CancelPage />} />
              <Route path="expire" element={<ExpirePage />} />
            </Route> */}
            <Route index path="ride/*" element={<RideHistoryPage />} />
            <Route path="more" element={<MoreFragment />} />
          </Route>

          <Route
            path="privacy"
            element={
              <div>
                <Outlet />
              </div>
            }
          >
            <Route path=":type" element={<PrivacyPolicyPage />} />
            <Route index element={<PolicyListPage />} />
          </Route>

          <Route index path="/ride-details/*" element={<RideDetailsPage />} />
          <Route index path="/ride-bidding/*" element={<RideBiddingPage />} />
          <Route
            index
            path="/bidding-details/*"
            element={<BiddingDetailsPage />}
          />
          <Route index path="/ride-summery/*" element={<AmountSummery />} />
          <Route
            index
            path="/payment-success/*"
            element={<PaymentSuccessPage />}
          />
          <Route index path="/coupons" element={<CouponPage />} />

          <Route
            path="/car-rental/*"
            element={
              <div>
                <Outlet />
              </div>
            }
          >
            <Route index path="" element={<CarTypeList />} />
            <Route path="input-address" element={<InputAddress />} />
            <Route path="final-check" element={<OverViewRideRequest />} />
            <Route path="ride-success/*" element={<SuccessPage />} />
          </Route>

          <Route path="/notification" element={<Notification />} />
          <Route path="/notification/:id" element={<NotificationDetails />} />
          <Route path="/banner/:id" element={<BannerDetails />} />

          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/verify-otp/:newUser/:phone"
            element={<VerifyOtpPage />}
          />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/distances" element={<Distances />} />
          <Route path="/map" element={<NewMaps />} />

          <Route path="/favorites-partner" element={<FavoritePartnerPage />} />
          <Route path="/tutorials" element={<TutorialPage />} />
          <Route path="/tutorials/:id" element={<TutorialDetailPage />} />
          <Route path="/quicar-point" element={<QuicarPoinPage />} />
          <Route path="/profile-setting" element={<ProfileSettingPage />} />
          <Route path="/activity" element={<UserActivity />} />
          <Route path="/contact-us" element={<Contactus />} />

          <Route path="*" element={<Navigate replace to={`/main/home`} />} />
        </Routes>
      </BrowserRouter>
    </div>

    // <Layout />
  );
}

export default App;
