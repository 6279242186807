
import * as actionTypes from '../actionTypes';


const initialState = {
    loading: false,
    list: [],
    paginate: null,
    type: "send",
    nextPage: 1,
    previousPage: 1,
    currentPage: 1,
    hasNextPage: true,
    hasPreviousPage: false,
    paging: [],
    pageSize: 10,
    error: null
}


const favoritePartnerReducer = (state = initialState, action) => {

    const { type, payload } = action

    switch (type) {
        case actionTypes.FAVORITE_PARTNER_REQUEST_SEND:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.FAVORITE_PARTNER_REQUEST_SUCCESS:

            return {
                ...state,
                loading: false,
                list: payload.start ? payload.list :[...state.list,...payload.list],
                error: null,
                message: null,
                paginate: payload.paginate,
                paging: payload.paginate.metadata.paging,
                hasNextPage: payload.paginate.metadata.hasNextPage,
                hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
                currentPage: payload.paginate.metadata.page.currentPage,
                nextPage: payload.paginate.metadata.page.nextPage,
                previousPage: payload.paginate.metadata.page.previousPage,
            };


        case actionTypes.FAVORITE_PARTNER_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            };

        default:
            return state;
    }

}


export default favoritePartnerReducer;