export function timeDifference(bigTime, current) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = bigTime - current;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds remaining";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes remaining";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours remaining";
  } else if (elapsed < msPerMonth) {
    return (
      "approximately " + Math.round(elapsed / msPerDay) + " days remaining"
    );
  } else if (elapsed < msPerYear) {
    return (
      "approximately " + Math.round(elapsed / msPerMonth) + " months remaining"
    );
  } else {
    return (
      "approximately " + Math.round(elapsed / msPerYear) + " years remaining"
    );
  }
}
