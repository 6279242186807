import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import { getSingleNotification } from "../../redux/notification/notificationAction";
import PageLayout from "../../components/layout/PageLayout";

const NotificationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const { notification, loading } = useSelector((state) => state.notification);

  useEffect(() => {
    dispatch(getSingleNotification(id));
  }, [id]);

  const goToInfoPage = (navId, navName) => {
    if (navName === "bid") {
      navigate(`/bidding-details?biddingId=${navId}`);
    } else if (["ride", "cancel"].includes(navName)) {
      navigate(`/ride-details?rideId=${navId}`);
    } else {
      navigate(`/notification`);
    }
  };

  return (
    <div>
      <PageLayout
        title="Notification"
        appbar={true}
        appBgColor="#F79520"
        bgColor="#f4f3f9"
        iconColor={"white"}
        titleColor="white"
        badgeIconColor="white"
        elevation={5}
      >
        {notification && !loading ? (
          <div>
            <div>
              <h3 style={{ padding: "10px 10px", color: "#efb103" }}>
                {notification?.title}
              </h3>
              <hr style={{ color: "lightgray" }} />
              {notification.image && (
                <img
                  src={notification?.image}
                  alt=""
                  style={{ width: "100%" }}
                />
              )}

              <div style={{ padding: "10px 10px" }}>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: notification.description,
                  }}
                  
                ></div> */}
                <h6>{notification?.description}</h6>
              </div>
            </div>

            {notification?.navId && notification?.navName && (
              <ButtonWrapper>
                <Button
                  color="info"
                  variant="outlined"
                  onClick={() =>
                    goToInfoPage(notification?.navId, notification?.navName)
                  }
                >
                  See Details
                </Button>
              </ButtonWrapper>
            )}
          </div>
        ) : (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        )}
      </PageLayout>
    </div>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export default NotificationDetails;
