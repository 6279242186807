import * as actionTypes from "../actionTypes";
import { requestApi } from "../../network/httpRequest";
import * as api from "../../network/urls";

export const homeAction = () => async (dispatch, getState) => {
  try {
    // const {value} = getState().reducerName

    dispatch({
      type: actionTypes.HOME_DATA_REQUEST_SEND,
    });

    const { data } = await requestApi().request(api.HOME_API, {
      method: "get",
    });

    if (data.status) {
      const { slider: banners, carRentalStatus } = data.data;
      dispatch({
        type: actionTypes.HOME_DATA_REQUEST_SUCCESS,
        payload: {
          banners: banners,
          carRentalStatus: carRentalStatus,
        },
      });
    } else {
      dispatch({
        type: actionTypes.HOME_DATA_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: actionTypes.HOME_DATA_REQUEST_FAIL,
      payload: e.message,
    });
  }
};

export const getSystemConfigs = () => async (dispatch, getState) => {
  try {
    // const {value} = getState().reducerName

    dispatch({
      type: actionTypes.GET_SYSTEM_CONFIGS_REQUEST_SEND,
    });

    const { data } = await requestApi().request(api.SYSTEM_CONFIGS);

    if (data.status) {
      const { configs } = data.data;
      dispatch({
        type: actionTypes.GET_SYSTEM_CONFIGS_REQUEST_SUCCESS,
        payload: configs,
      });
    } else {
      dispatch({
        type: actionTypes.GET_SYSTEM_CONFIGS_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: actionTypes.GET_SYSTEM_CONFIGS_REQUEST_FAIL,
      payload: e.message,
    });
  }
};

// GET ALL NOTICES
export const getNotices = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionTypes.NOTICE_LIST_REQUEST_SEND,
    });

    const { data } = await requestApi().request(api.NOTICES);

    if (data.status) {
      dispatch({
        type: actionTypes.NOTICE_LIST_REQUEST_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: actionTypes.NOTICE_LIST_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: actionTypes.NOTICE_LIST_REQUEST_FAIL,
      payload: e.message,
    });
  }
};

export const getSingleConfig = (code) => async (dispatch, getState) => {
  try {
    // const {value} = getState().reducerName

    dispatch({
      type: actionTypes.GET_SINGLE_CONFIG_REQUEST_SEND,
    });

    const { data } = await requestApi().request(api.SINGLE_SYSTEM_CONFIG, {
      params: {
        code,
      },
    });

    if (data.status) {
      const { config } = data.data;
      dispatch({
        type: actionTypes.GET_SINGLE_CONFIG_REQUEST_SUCCESS,
        payload: config,
      });
    } else {
      dispatch({
        type: actionTypes.GET_SINGLE_CONFIG_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: actionTypes.GET_SINGLE_CONFIG_REQUEST_FAIL,
      payload: e.message,
    });
  }
};
