import {
  ACTIVITY_LIST_REQUEST_FAIL,
  ACTIVITY_LIST_REQUEST_SEND,
  ACTIVITY_LIST_REQUEST_SUCCESS,
} from "../actionTypes";

const initialState = {
  loading: false,
  status: false,
  error: null,
  activities: [],
  paginate: null,
  nextPage: 1,
  previousPage: 1,
  currentPage: 1,
  hasNextPage: true,
  hasPreviousPage: false,
  paging: [],
  pageSize: 10,
};

const activityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTIVITY_LIST_REQUEST_SEND:
      return {
        ...state,
        loading: true,
      };

    case ACTIVITY_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        activities: payload.recentHistories,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        nextPage: payload.paginate.metadata.page.nextPage,
        previousPage: payload.paginate.metadata.page.previousPage,
      };

    case ACTIVITY_LIST_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default activityReducer;
