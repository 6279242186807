import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Avatar } from "@mui/material";

const UserMessageView = ({ item, length, index }) => {
  const { bidding } = useSelector((state) => state.rideReducer);

  const checkAcceptance = (e) => {
    if (e["acceptedPrice"]) {
      return "You Accepted Offer";
    }
    return "You Sent Offer";
  };

  return (
    <Wrapper>
      <Avatar
        alt={bidding?.ride?.user?.name}
        src={bidding?.ride?.user?.img}
        sx={{ width: 40, height: 40 }}
      />

      <MessageSession>
        <div>
          <div style={{ lineHeight: "13px" }}>
            <span className="message">{checkAcceptance(item)}</span>
            <p>
              <strong>{item.biddingAmount}Tk.</strong>
            </p>
          </div>
          {/* {(length-1) === index && item.offerSendBy ==="partner"? (
            <div
              style={{
                paddingTop: "15px",
                fontSize: "13px",
                cursor: "pointer",
                // backgroundColor: "#e48922",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                color: "#e48922",
                fontWeight: "bold",
              }}>
              <span>Send another offer</span>
            </div>
          ) : (
            <div></div>
          )} */}
        </div>
      </MessageSession>
    </Wrapper>
  );
};

const userChatBg = "#016064";

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: end;
  /* reverse view  */
  flex-direction: row-reverse;
`;

const ChatAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
`;

const MessageSession = styled.div`
  margin-right: 12px;
  margin-top: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 18px;
  padding-right: 18px;
  background: ${userChatBg};
  border-radius: 11px 0px 11px 11px;
  position: relative;
  color: white;
  &:after {
    content: " ";
    position: absolute;
    left: 100%;
    top: 0;
    border: solid transparent;
    border-left-color: ${userChatBg};
    border-top-color: ${userChatBg};
    border-width: 5px;
  }
  p {
    margin: 0px;
    padding-top: 3px;
  }

  .message {
    font-weight: 600;
    position: relative;
    font-size: 12px;
    color: white;
  }
`;

export default UserMessageView;
