import React, { useEffect, useState } from "react";
import styledComponent from "styled-components";
import RideHistoryItem from "../../components/rideHistory/RideHistoryItem";
import {
  changeGetRideListType,
  getRideList,
} from "../../redux/ride/rideAction";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import HistoryIcon from "@mui/icons-material/History";
import { getNotificationList } from "../../redux/notification/notificationAction";
import PageLayout from "../../components/layout/PageLayout";
import NotificationItem from "../../components/NotificationItem";

const Notification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { notifications, badge, loading, hasNextPage } = useSelector(
    (state) => state.notification
  );

  useEffect(() => {
    dispatch(getNotificationList(true));
  }, []);

  const loadMore = () => {
    dispatch(getNotificationList(false));
  };

  return (
    <div style={{ position: "relative" }}>
      {/* <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <RideSelection/>
            </div> */}

      <PageLayout
        title="Notification"
        appbar={true}
        notificationBadge={true}
        appBgColor="#F79520"
        bgColor="white"
        iconColor={"white"}
        titleColor="white"
        badgeIconColor="white"
        elevation={5}
      >
        <div
          style={{
            height: hasNextPage ? "90%" : "100%",
            overflow: "auto",
          }}
        >
          {notifications.map((item, index) => {
            return <NotificationItem item={item} key={index} />;
          })}
        </div>

        {loading && (
          <CenterDiv>
            <CircularProgress size={25} />
          </CenterDiv>
        )}

        {hasNextPage && !loading && (
          <CenterDiv>
            <Button onClick={loadMore}>Load More</Button>
          </CenterDiv>
        )}

        {notifications.length < 1 && !loading && (
          <CenterVertical>
            <HistoryIcon
              style={{ width: "50px", height: "50px", fill: "grey" }}
            ></HistoryIcon>
          </CenterVertical>
        )}
      </PageLayout>
    </div>
  );
};

const CenterDiv = styledComponent.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;


`;
const CenterVertical = styledComponent.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    width:100%;
    height:80vh;
    justify-content: center;
    align-items: center;
`;

export default Notification;
