import { requestApi } from "../../network/httpRequest";
import {
  ACCEPT_BIDDING_OFFER,
  BIDDING_OFFER_SEND_API,
  CANCEL_REASONS,
  CANCEL_RIDE,
  CANCEL_RIDE_CUT_CAL,
  GET_RIDE_LIST,
  GET_SINGLE_BIDDING,
  GIVE_REVIEW_TO_PARTNER,
} from "../../network/urls";
import * as actionType from "../actionTypes";

export const getRideList = (start) => async (dispatch, getState) => {
  try {
    const { rides, hasNextPage, nextPage, type, pageSize } =
      getState().rideReducer;

    dispatch({
      type: actionType.RIDE_LIST_REQUEST_SEND,
    });

    const { data } = await requestApi().request(GET_RIDE_LIST, {
      params: {
        type: type,
        page: start ? 1 : nextPage,
        pageSize: pageSize,
      },
    });

    if (data.status) {
      dispatch({
        type: actionType.RIDE_LIST_REQUEST_SUCCESS,
        payload: {
          start: start,
          rides: data.data.rides,
          paginate: data.data.paginate,
        },
      });
    } else {
      dispatch({
        type: actionType.RIDE_LIST_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.RIDE_LIST_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const changeGetRideListType = (type) => async (dispatch, getState) => {
  dispatch({
    type: actionType.RIDE_LIST_REQUEST_TYPE_CHANGE,
    payload: type,
  });
};

export const getSingleBid = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionType.SINGLE_RIDE_BIDDING_DETAIL_REQUEST_SEND,
    });

    const { data } = await requestApi().request(GET_SINGLE_BIDDING, {
      params: {
        biddingId: id,
      },
    });

    if (data.status) {
      dispatch({
        type: actionType.SINGLE_RIDE_BIDDING_DETAIL_REQUEST_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: actionType.SINGLE_RIDE_BIDDING_DETAIL_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.SINGLE_RIDE_BIDDING_DETAIL_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const offerSent = (biddingId, amount) => async (dispatch, getState) => {
  try {
    const socket = getState().socketReducer.socket;

    dispatch({
      type: actionType.BIDDING_OFFER_REQUEST_SEND,
    });

    const { data } = await requestApi().request(BIDDING_OFFER_SEND_API, {
      method: "POST",
      data: {
        bidId: biddingId,
        biddingAmount: amount,
      },
    });

    console.log("data", data);

    if (data.status) {
      socket.emit("bidding_offer_send", {
        room: data.data.biddings["ride"]["bookingId"],
      });

      dispatch({
        type: actionType.BIDDING_OFFER_REQUEST_SUCCESS,
        payload: data.data.biddings,
      });
    } else {
      dispatch({
        type: actionType.BIDDING_OFFER_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {}
};

export const partnerOfferAccept = (amountId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionType.PARTNER_OFFER_ACCEPT_REQUEST_SEND,
    });

    const { data } = await requestApi().request(ACCEPT_BIDDING_OFFER, {
      method: "POST",
      data: { amountId },
    });

    if (data.status) {
      dispatch({
        type: actionType.PARTNER_OFFER_ACCEPT_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: actionType.PARTNER_OFFER_ACCEPT_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.PARTNER_OFFER_ACCEPT_FAIL,
      payload: error.message,
    });
  }
};

export const getCancelReasons = (type) => async (dispatch) => {
  console.log(type);
  try {
    dispatch({
      type: actionType.GET_CANCEL_REASONS_REQUEST_SEND,
    });
    const { data } = await requestApi().request(CANCEL_REASONS, {
      params: {
        type,
      },
    });

    if (data.status) {
      const { cancelReasons } = data.data;
      dispatch({
        type: actionType.GET_CANCEL_REASONS_REQUEST_SUCCESS,
        payload: cancelReasons,
      });
    } else {
      dispatch({
        type: actionType.GET_CANCEL_REASONS_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.GET_CANCEL_REASONS_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const cancelRideByUser = (values) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.CANCEL_RIDE_REQUEST_SEND,
    });
    const { data } = await requestApi().request(CANCEL_RIDE, {
      method: "POST",
      data: values,
    });

    if (data.status) {
      dispatch({
        type: actionType.CANCEL_RIDE_REQUEST_SUCCESS,
        payload: {
          id: values?.rideId,
          message: data.message,
        },
      });
    } else {
      dispatch({
        type: actionType.CANCEL_RIDE_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.CANCEL_RIDE_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const getCancelCutData = (rideId) => async (dispatch) => {
  console.log("call here to cut");
  try {
    dispatch({
      type: actionType.GET_CANCEL_RIDE_CUT_CAL_SEND,
    });
    const { data } = await requestApi().request(
      CANCEL_RIDE_CUT_CAL + `/${rideId}`
    );

    if (!data.status) {
      throw new Error("Data not found!.");
    }

    dispatch({
      type: actionType.GET_CANCEL_RIDE_CUT_CAL_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    dispatch({
      type: actionType.GET_CANCEL_RIDE_CUT_CAL_FAIL,
      payload: error.message,
    });
  }
};

export const reviewToPartner = (reviewData) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.PARTNER_REVIEW_SEND,
    });
    const { data } = await requestApi().request(GIVE_REVIEW_TO_PARTNER, {
      method: "POST",
      data: reviewData,
    });

    console.log(data);

    if (!data.status) {
      throw new Error(data?.message);
    }

    const { review } = data?.data;

    dispatch({
      type: actionType.PARTNER_REVIEW_SUCCESS,
      payload: data?.message,
    });
  } catch (error) {
    dispatch({
      type: actionType.PARTNER_REVIEW_FAIL,
      payload: error?.message,
    });
  }
};
