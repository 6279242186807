import * as actionType from "../actionTypes";


export const addBkashInit = (data) => async (dispatch, getState) => {

    dispatch({
        type: actionType.BKASH_PAYMENT_REQUEST_DATA_ADD,
        payload: data
    });
}

export const addCoupon = (data) => async (dispatch, getState) => {
    dispatch({
        type: actionType.COUPON_ADD,
        payload: data
    });
}

export const addDiscount = (data) => async (dispatch, getState) => {
    dispatch({
        type: actionType.DISCOUNT_ADD,
        payload: data
    });
}

export const addBiddingId = (data) => async (dispatch, getState) => {
    dispatch({
        type: actionType.ADD_RIDE_ID,
        payload: data
    });
}

