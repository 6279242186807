import React, { useEffect, useState } from "react";
import PageLayout from "../../components/layout/PageLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { rideBiddings } from "../../redux/rideBidding/rideBiddingAction";
import { CircularProgress } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { Divider } from "@mui/material";

const RideBiddingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [rideId, setRideId] = useState("");
  const { biddings, loading } = useSelector(
    (state) => state.rideBiddingsReducer
  );

  useEffect(() => {
    const id = searchParams.get("rideId");
    if (id) {
      setRideId(id);
      dispatch(rideBiddings(id));
    } else {
      navigate("/main/home", {
        replace: true,
      });
    }
  }, []);

  const getBiddingAmount = (amounts) => {
    const partnersOffers = amounts.filter(
      (item) => item["offerSendBy"] === "partner"
    );
    const lastOffer = partnersOffers[0];
    return lastOffer["biddingAmount"];
  };

  const getAcceptedTitle = (item) => {
    if (item["giveAdvancePayment"]) {
      return `Advance Payment ${item["advanceAmount"]} Tk`;
    } else {
      return "No Advance Payment";
    }

    // let distanceFromPickUpLocation = biddings['distanceFromPickUpLocation'];
    // let distance = distanceFromPickUpLocation / 1000;
    // let amount = biddings['bidding_amounts'][biddings['bidding_amounts'].length - 1];
    // let biddingAmount = amount['biddingAmount'];
    // // const where = userPaymentConditions.find((item) => distance >= item['minKilometer'] && distance <= item['maxKilometer']);
    // // if (where && where.percentage > 0) return `With Advance payment ${(biddingAmount * where.percentage) / 100} tk`;
    // return "Without advance payment";
  };

  return (
    <PageLayout
      title="Ride Bidding"
      appbar={true}
      appBgColor="#F79520"
      bgColor="#f4f3f9"
      iconColor={"white"}
      titleColor="white"
      badgeIconColor="white"
      elevation={5}
    >
      <div style={{ height: "100%", backgroundColor: "#F1F1F1" }}>
        {biddings.map((item, index) => {
          return (
            <ItemBidding key={index}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      alt=""
                      width="50"
                      height="50"
                      style={{ objectFit: "cover", borderRadius: "10px" }}
                      src={item?.partner?.img}
                    />
                  </div>

                  <div style={{ paddingLeft: "10px" }}>
                    <p style={{ fontWeight: "600", fontSize: "18px" }}>
                      {item?.partner?.name}
                    </p>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10.052"
                        height="9.788"
                        viewBox="0 0 10.052 9.788"
                      >
                        <path
                          id="Icon_ionic-md-star"
                          data-name="Icon ionic-md-star"
                          d="M8.823,12.368l3.106,1.92-.822-3.621,2.742-2.435-3.614-.318L8.823,4.5,7.411,7.914,3.8,8.232l2.742,2.435-.822,3.621Z"
                          transform="translate(-3.797 -4.5)"
                          fill="#f79520"
                        />
                      </svg>
                      <span style={{ paddingLeft: "5px" }}>
                        {item.partner.rating}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                  }}
                >
                  <p>Bidding Price</p>
                  <p style={{ paddingRight: "20px" }}>
                    {getBiddingAmount(item.bidding_amounts)} Tk
                  </p>
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#f79520",
                      fontWeight: "bold",
                    }}
                  >
                    {getAcceptedTitle(item)}
                  </span>
                </div>
              </div>

              <div style={{ padding: "5px 0px" }}>
                <Divider />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      alt="img"
                      width="50"
                      height="50"
                      style={{ objectFit: "cover", borderRadius: "10px" }}
                      src={item?.car?.carFrontImage}
                    />
                  </div>

                  <div style={{ paddingLeft: "10px" }}>
                    <p>
                      {item?.car?.car_type?.name} - {item?.car?.car_brand?.name}
                    </p>
                    <div>
                      <span>
                        {item?.car?.car_model?.name} - {item?.car?.year?.year}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      paddingLeft: "10px",
                      color: "#000000",
                      fontWeight: "bold",
                    }}
                    onClick={() =>
                      navigate(`/bidding-details?biddingId=${item.id}`)
                    }
                  >
                    View Details
                  </p>

                  {/* <button
                  className="details"
                  onClick={() => navigate(`/ride-details?bidding=${item.id}`)}
                >
                  {" "}
                  Details{" "}
                </button> */}
                </div>
              </div>
            </ItemBidding>
          );
        })}

        {loading && (
          <CenterDiv>
            <CircularProgress size={25} />
          </CenterDiv>
        )}

        {biddings.length < 1 && !loading && (
          <CenterVertical>
            <HistoryIcon
              style={{ width: "50px", height: "50px", fill: "grey" }}
            ></HistoryIcon>
          </CenterVertical>
        )}
      </div>
    </PageLayout>
  );
};

const ItemBidding = styled.div`
  margin: 10px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const Page = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(197, 197, 197);
  .wrapper {
    height: 100vh;
    width: 100%;
    /* box-shadow: -1px -1px 5px 2px rgba(238, 238, 238, 0.842);
      -webkit-box-shadow: -1px -1px 5px 2px rgba(238, 238, 238, 0.842);
      -moz-box-shadow: -1px -1px 5px 2px rgba(238, 238, 238, 0.842); */
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (min-width: 767.98px) {
      width: 440px;
      height: calc(100vh - 60px);
      border-radius: 20px;
    }

    .content {
      width: 100%;
      flex: 1;
      background-color: #ffffff;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none; /* Firefox */
    }
  }
`;
const CenterDiv = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const CenterVertical = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
`;
export default RideBiddingPage;
