export const apiUrl = "https://api.quicarbd.com";
//export const apiUrl = "http://localhost:5001";
//export const apiUrl = "http://192.168.253.70:5001";
export const SOCKET_CONNECTION = "https://api.quicarbd.com";
// export const SOCKET_CONNECTION = "http://localhost:5000";
export const FTP_SERVER_URL = "https://ftp2.quicarbd.com";
const RANTAL_END_POINT = "/api/v1/web/rental";

export const UPLOAD_MULTIPLE_IMAGE = FTP_SERVER_URL + "/upload";

export const BKASH_PAYMENT_CREATE =
  apiUrl + RANTAL_END_POINT + "/bkash-payment/create-user-payment";
export const BKASH_PAYMENT_EXECUTE =
  apiUrl + RANTAL_END_POINT + "/bkash-payment/execute-user-payment";

export const CHECK_DISCOUNT =
  apiUrl + RANTAL_END_POINT + "/car-rental/check-discount";
export const APPLY_COUPON =
  apiUrl + RANTAL_END_POINT + "/car-rental/check-coupon";

export const SEND_LOGIN_OTP = apiUrl + RANTAL_END_POINT + "/user/send-otp";
export const VERIFY_OTP = RANTAL_END_POINT + "/user/otp-verify";
export const REGISTRATION = RANTAL_END_POINT + "/user/registration";

export const HOME_API = RANTAL_END_POINT + "/home";
export const BANNER_BY_ID = RANTAL_END_POINT + "/banner/";
export const GET_CAR_TYPE = RANTAL_END_POINT + "/cartype";

export const RENTAL_REQUEST = RANTAL_END_POINT + "/car-rental/request-send";

export const GET_RIDE_LIST = RANTAL_END_POINT + "/car-rental";
export const GET_BIDDING_LIST =
  RANTAL_END_POINT + "/car-rental/get-all-bidding";
export const GET_SINGLE_BIDDING =
  RANTAL_END_POINT + "/car-rental/get-single-bidding";
export const GET_SINGLE_RIDE = RANTAL_END_POINT + "/car-rental/get-single-ride";
export const CANCEL_RIDE_CUT_CAL =
  RANTAL_END_POINT + "/car-rental/cancel-ride-cut";
export const CANCEL_RIDE = RANTAL_END_POINT + "/car-rental/cancel-ride";
export const GET_PRIVACY_POLICY = RANTAL_END_POINT + "/privacy/web";

// http://localhost:5000/api/v1/web/rental/car-rental/get-all-bidding?rideId=1

// export const getUserList = "api/admin/user";
// export const getUserInfo = "api/admin/user/getUserInfo";
// export const carRentalSummary = "api/admin/car-rental/summary";

export const NOTIFICATION = RANTAL_END_POINT + "/notification";
export const NOTIFICATION_SEEN = RANTAL_END_POINT + "/notification/seen";
export const NOTIFICATION_BADGE = RANTAL_END_POINT + "/notification/badge";

export const GET_TURIRIALS = RANTAL_END_POINT + "/tutorial";

export const GET_TRANSACTION_LIST =
  RANTAL_END_POINT + "/user/get-my-transaction/";

export const FAVORITE_PARTNER = RANTAL_END_POINT + "/favorite-partners";
export const USER_PROFILE = RANTAL_END_POINT + "/user/my-profile";
export const USER_PROFILE_UPDATE = RANTAL_END_POINT + "/user/update-profile";

export const BIDDING_OFFER_SEND_API =
  RANTAL_END_POINT + "/car-rental/send-bidding-offer";

export const ACCEPT_BIDDING_OFFER = RANTAL_END_POINT + "/car-rental/accept-bid";
export const COUPON_LIST = RANTAL_END_POINT + "/coupon";

// RECENT ACTIVITY
export const ALL_ACTIVITY = RANTAL_END_POINT + "/recent-history";

// SYSTEM CONFIGS

export const SYSTEM_CONFIGS = RANTAL_END_POINT + "/system-configs/get-all";
export const SINGLE_SYSTEM_CONFIG = RANTAL_END_POINT + "/system-configs/";

// NOTICES
export const NOTICES = RANTAL_END_POINT + "/notices";

// CANCEL REASONS

export const CANCEL_REASONS = RANTAL_END_POINT + "/cancel-reason";

// ADVANCE PAYEMNT WITH QUICAR PAYMENT

export const PAYMENT_WITH_QUICAR_POINT =
  RANTAL_END_POINT + "/car-rental/make-advance-payment-by-point";

// GIVE REVIEW TO PARTNER

export const GIVE_REVIEW_TO_PARTNER =
  RANTAL_END_POINT + "/review/send-review-to-partner";

//  WITHDRAW POINT REQUEST

export const WITHDRAW_POINT = RANTAL_END_POINT + "/user/withdraw-request";

// GOOGLE PLAY STORE USER APP LINK

export const USER_APP_GOOGLE_PLAY_STORE =
  "https://play.google.com/store/apps/details?id=com.app.quicarapp";
