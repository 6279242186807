import { Box, Button, Input, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageLayout from "../../components/layout/PageLayout";
import "./login.scss";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { requestApi } from "../../network/httpRequest";
import { SEND_LOGIN_OTP, USER_APP_GOOGLE_PLAY_STORE } from "../../network/urls";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import PageLayoutLoginCheckNon from "../../components/layout/PageLayoutLoginCheckNon";
import { useSelector } from "react-redux";
import axios from "axios";
import { PlayStoreIcon } from "../../helpers/staticData";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginPage = () => {
  const [number, setNumber] = useState("");
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  const { accessToken, user } = useSelector((state) => state.usereducer);
  useEffect(() => {
    if (accessToken && user) {
      navigate("/main/home");
    }
  }, [accessToken, user]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (number) {
      if (number.length == 11) {
        setisLoading(true);

        const { data } = await axios.request(SEND_LOGIN_OTP, {
          method: "POST",
          data: {
            phone: number,
          },
        });

        if (data?.status) {
          setisLoading(false);
          navigate(`/verify-otp/${data?.newUser}/${number}`);
        } else {
          setErrorMessage(data?.error);
          setOpen(true);
          setisLoading(false);
        }
      } else {
        setErrorMessage("Enter a valid number");
        setOpen(true);
      }
    } else {
      setErrorMessage("Enter a number please");
      setOpen(true);
    }
  };

  return (
    <div>
      <PageLayoutLoginCheckNon appbar={false}>
        <Wrapper>
          <div style={{ paddingTop: "20px" }}>
            <h3>Welcome Back!</h3>
            <p>Login to continue</p>
          </div>
          <ImageWrapper>
            <img
              style={{ height: "150px" }}
              src="./images/local/login_screen_image.svg"
              alt=""
            />
          </ImageWrapper>
          <ContentWrapper>
            <form onSubmit={onSubmit}>
              <h4>Verify Your Mobile Number</h4>
              <p className="color_grey">
                Please enter your number to <br />
                verify your account
              </p>

              <div style={{ height: "20px" }}></div>

              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                  value={number}
                  label="Phone Number"
                  id="outlined-start-adornment"
                  sx={{ m: 1, width: "25ch" }}
                  onChange={(e) => setNumber(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+88</InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <Alert
                  onClose={() => setOpen(false)}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </Alert>
              </Snackbar>
              <div style={{ height: "10px" }}></div>
              <div style={{ width: "100%" }}>
                <Button
                  disabled={isLoading}
                  type="submit"
                  //   onClick={onSubmit}
                  style={{
                    height: "40px",
                    backgroundColor: isLoading ? "#B9B9B8" : "#F79520",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  variant="contained"
                >
                  <span></span>
                  <span>Send</span>

                  {isLoading ? (
                    <CircularProgress size={18} color="inherit" />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                    >
                      <g
                        id="Group_6412"
                        data-name="Group 6412"
                        transform="translate(-277 -762)"
                      >
                        <circle
                          id="Ellipse_822"
                          data-name="Ellipse 822"
                          cx="11"
                          cy="11"
                          r="11"
                          transform="translate(277 762)"
                          fill="#fff"
                          opacity="0.27"
                        />
                        <path
                          id="Icon_ionic-ios-arrow-back"
                          data-name="Icon ionic-ios-arrow-back"
                          d="M12.665,10.3l3.106-3.1a.587.587,0,1,0-.831-.828L11.421,9.882a.586.586,0,0,0-.017.809l3.534,3.541a.587.587,0,0,0,.831-.829Z"
                          transform="translate(301.597 783.298) rotate(180)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  )}
                </Button>
              </div>
              <div style={{ height: "30px" }}></div>
            </form>

            <GooglePayStoreWrapper>
              <a
                className="image_wrapper"
                href={USER_APP_GOOGLE_PLAY_STORE}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                  alt="GOOGLE PLAY STORE"
                  height="100%"
                  width="100%"
                />
              </a>
              <small className="play_store_text">
                For a better experience, Download Mobile App
              </small>
            </GooglePayStoreWrapper>

            <div style={{ color: "gray" }}>
              <small>{`© ${new Date().getFullYear()} Quicar`}</small>
              <small
                style={{ marginLeft: "10px", textDecoration: "underline" }}
                className="cursor-pointer"
                onClick={() => navigate("/privacy")}
              >
                Privacy
              </small>
            </div>
          </ContentWrapper>
        </Wrapper>
      </PageLayoutLoginCheckNon>
    </div>
  );
};

const Wrapper = styled.div`
  padding: 10px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
  text-align: center;

  .color_grey {
    color: gray;
  }
`;

const GooglePayStoreWrapper = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .image_wrapper {
    width: 100px;
    height: 50px;
    cursor: pointer;
  }
  .play_store_text {
    color: gray;
  }
`;

export default LoginPage;
