import React, { useEffect, useState } from "react";
import { Badge } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import { Toolbar } from "@mui/material";
import { AppBar } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import RideSelection from "../rideSelection/RideSelection";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import QuicarLogo from "../QuicarLogo";
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showRideMenu, setShowRideMenu] = useState(false);
  const { badge } = useSelector((state) => state.notification);

  useEffect(() => {
    if (location.pathname === "/main/ride") {
      setShowRideMenu(true);
    } else {
      setShowRideMenu(false);
    }
  }, [location.pathname]);

  return (
    <AppBar position="static" style={{ background: "white" }} elevation={0}>
      <Toolbar>
        <Typography
          noWrap
          component="div"
          sx={{ display: { xs: "block", sm: "block" } }}
        >
          {/* <img src="/images/local/quicar_logo_dark" /> */}
          <QuicarLogo/>
          {/* <h2 style={{ color: 'black' }}>Logo</h2> */}
        </Typography>

        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: "flex", md: "flex" } }}>
          {showRideMenu == true ? (
            <RideSelection />
          ) : (
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={() => navigate("/notification")}
            >
              <Badge badgeContent={badge > 0 ? badge : 0} color="error">
                <NotificationsIcon style={{ color: "black" }} />
              </Badge>
            </IconButton>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
