import { CircularProgress } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import CenterDiv from "../../components/CenterDiv";
import PageLayout from "../../components/layout/PageLayout";
import { getTutorials } from "../../redux/tutorial/tutorialAction";
import { useNavigate, useParams } from "react-router-dom";

const TutorialPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, list } = useSelector((state) => state.tutorial);

  useEffect(() => {
    dispatch(getTutorials());
  }, []);

  return (
    <PageLayout
      title="Tutorials"
      appbar={true}
      appBgColor="#F79520"
      titleColor="white"
      iconColor="white"
    >
      {!loading ? (
        list.map((item, index) => {
          return (
            <Wrapper
              onClick={() => {
                navigate(`/tutorials/${item.id}`);
              }}
              key={index}
            >
              <p style={{ paddingBottom: "5px" }}>{item.title}</p>
              <div style={{ position: "relative" }}>
                <YoutubeThumnail key={index} alt="" src={item.thumbnail} />

                <div
                  style={{
                    height: "50px",
                    // backgroundColor: "#80808082",
                    borderRadius: "20px",
                    position: "absolute",
                    bottom: "5px",
                    right: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  // style={{
                  //   height: "50px",
                  //   backgroundColor: "#80808082",
                  //   borderRadius: "20px",
                  //   position: "absolute",
                  //   top: "50%",
                  //   left: "50%",
                  //   transform: "translate(-50%, -50%)",
                  // }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    style={{ height: "40px" }}
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                    />
                  </svg>
                  <span> Play</span>
                </div>
              </div>
            </Wrapper>
          );
        })
      ) : (
        <CenterDiv>
          <CircularProgress />
        </CenterDiv>
      )}
    </PageLayout>
  );
};

const YoutubeThumnail = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const Wrapper = styled.div`
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  shadow: 0 0 5px #ccc;
  cursor: pointer;
`;

export default TutorialPage;
