// REDUX ACTION TYPES

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGIN = "USER_LOGIN";
export const USER_UPDATE_REDUX = "USER_UPDATE_REDUX";

export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_REQUEST_FAIL = "GET_USER_INFO_REQUEST_FAIL";
export const GET_USER_INFO_REQUEST_SUCCESS = "GET_USER_INFO_REQUEST_SUCCESS";
export const UPDATE_PROFILE_INFORMATION = "UPDATE_PROFILE_INFORMATION";

export const LOGIN_OTP_REQUEST_SEND = "LOGIN_OTP_SEND_REQUEST_FAIL";
export const LOGIN_OTP_REQUEST_FAIL = "LOGIN_OTP_SEND_REQUEST_FAIL";
export const LOGIN_OTP_REQUEST_SUCCESS = "LOGIN_OTP_SEND_REQUEST_FAIL";

export const SELECT_CAR_TYPE = "SELECT_CAR_TYPE";
export const SELECT_CAR_RENTAL_ITEM = "SELECT_CAR_RENTAL_ITEM";
export const SELECT_CAR_TYPE_FAIL = "SELECT_CAR_TYPE_FAIL";
export const CLEAR_CAR_RENTAL_REQUEST_DATA_START =
  "CLEAR_CAR_RENTAL_REQUEST_DATA_START";

// GET CAR TYPE LIST
export const CAR_TYPE_REQUEST_SEND = "CAR_TYPE_REQUEST_SEND";
export const CAR_TYPE_REQUEST_SUCCESS = "CAR_TYPE_REQUEST_SUCCESS";
export const CAR_TYPE_REQUEST_FAIL = "CAR_TYPE_REQUEST_FAIL";

// GET CAR TYPE LIST
export const HOME_DATA_REQUEST_SEND = "HOME_DATA_REQUEST_SEND";
export const HOME_DATA_REQUEST_SUCCESS = "HOME_DATA_REQUEST_SUCCESS";
export const HOME_DATA_REQUEST_FAIL = "HOME_DATA_REQUEST_FAIL";

// GET RIDE LIST
export const RIDE_LIST_REQUEST_SEND = "RIDE_LIST_REQUEST_SEND";
export const RIDE_LIST_REQUEST_SUCCESS = "RIDE_LIST_REQUEST_SUCCESS";
export const RIDE_LIST_REQUEST_FAIL = "RIDE_LIST_REQUEST_FAIL";
export const RIDE_LIST_REQUEST_TYPE_CHANGE = "RIDE_LIST_REQUEST_TYPE_CHANGE";

// GET RIDE LIST
export const PRIVACY_REQUEST_SEND = "PRIVACY_REQUEST_SEND";
export const PRIVACY_REQUEST_SUCCESS = "PRIVACY_REQUEST_SUCCESS";
export const PRIVACY_REQUEST_FAIL = "PRIVACY_REQUEST_FAIL";
export const PRIVACY_REQUEST_TYPE_CHANGE = "PRIVACY_REQUEST_TYPE_CHANGE";

// GET RIDE BIDDING LIST
export const RIDE_BIDDING_LIST_REQUEST_SEND = "RIDE_BIDDING_LIST_REQUEST_SEND";
export const RIDE_BIDDING_LIST_REQUEST_SUCCESS =
  "RIDE_BIDDING_LIST_REQUEST_SUCCESS";
export const RIDE_BIDDING_LIST_REQUEST_FAIL = "RIDE_BIDDING_LIST_REQUEST_FAIL";

// GET RIDE BIDDING LIST
export const SOCKET_REQUEST_SEND = "SOCKET_REQUEST_SEND";
export const SOCKET_REQUEST_SUCCESS = "SOCKET_REQUEST_SUCCESS";
export const SOCKET_REQUEST_FAIL =
  "RIDE_BIDDING_LSOCKET_REQUEST_FAILIST_REQUEST_FAIL";

// GET RIDE BIDDING LIST
export const FAVORITE_PARTNER_REQUEST_SEND = "FAVORITE_PARTNER_REQUEST_SEND";
export const FAVORITE_PARTNER_REQUEST_SUCCESS =
  "FAVORITE_PARTNER_REQUEST_SUCCESS";
export const FAVORITE_PARTNER_REQUEST_FAIL = "FAVORITE_PARTNER_REQUEST_FAIL";

// NOTIFICATION
export const NOTIFICATION_LIST_REQUEST_SEND = "NOTIFICATION_LIST_REQUEST_SEND";
export const NOTIFICATION_LIST_REQUEST_SUCCESS =
  "NOTIFICATION_LIST_REQUEST_SUCCESS";
export const NOTIFICATION_LIST_REQUEST_FAIL = "NOTIFICATION_LIST_REQUEST_FAIL";
export const NOTIFICATION_BADGE_REQUEST_SUCCESS =
  "NOTIFICATION_BADGE_REQUEST_SUCCESS";
export const NOTIFICATION_SINGLE_REQUEST_SUCCESS =
  "NOTIFICATION_SINGLE_REQUEST_SUCCESS";

// Totorial
export const TUTORIAL_REQUEST_SEND = "TUTORIAL_REQUEST_SEND";
export const TUTORIAL_REQUEST_SUCCESS = "TUTORIAL_REQUEST_SUCCESS";
export const SINGLE_TUTORIAL_REQUEST_SUCCESS =
  "SINGLE_TUTORIAL_REQUEST_SUCCESS";
export const TUTORIAL_REQUEST_FAIL = "TUTORIAL_REQUEST_FAIL";

// NOTIFICATION
export const TNX_LIST_REQUEST_SEND = "TNX_LIST_REQUEST_SEND";
export const TNX_LIST_REQUEST_SUCCESS = "TNX_LIST_REQUEST_SUCCESS";
export const TNX_LIST_REQUEST_FAIL = "TNX_LIST_REQUEST_FAIL";
export const TNX_SINGLE_REQUEST_SUCCESS = "TNX_SINGLE_REQUEST_SUCCESS";

export const GET_USER_INFO_REQUEST__ = "GET_USER_INFO_REQUEST__";
export const GET_USER_INFO_REQUEST_FAIL__ = "GET_USER_INFO_REQUEST_FAIL__";
export const GET_USER_INFO_REQUEST_SUCCESS__ =
  "GET_USER_INFO_REQUEST_SUCCESS__";

export const SINGLE_RIDE_BIDDING_DETAIL_REQUEST_SEND =
  "SINGLE_RIDE_BIDDING_DETAIL_REQUEST_SEND";
export const SINGLE_RIDE_BIDDING_DETAIL_REQUEST_SUCCESS =
  "SINGLE_RIDE_BIDDING_DETAIL_REQUEST_SUCCESS";
export const SINGLE_RIDE_BIDDING_DETAIL_REQUEST_FAIL =
  "SINGLE_RIDE_BIDDING_DETAIL_REQUEST_FAIL";

export const GET_CANCEL_REASONS_REQUEST_SEND =
  "GET_CANCEL_REASONS_REQUEST_SEND";
export const GET_CANCEL_REASONS_REQUEST_SUCCESS =
  "GET_CANCEL_REASONS_REQUEST_SUCCESS";
export const GET_CANCEL_REASONS_REQUEST_FAIL =
  "GET_CANCEL_REASONS_REQUEST_FAIL";

export const CANCEL_RIDE_REQUEST_SEND = "CANCEL_RIDE_REQUEST_SEND";
export const CANCEL_RIDE_REQUEST_SUCCESS = "CANCEL_RIDE_REQUEST_SUCCESS";
export const CANCEL_RIDE_REQUEST_FAIL = "CANCEL_RIDE_REQUEST_FAIL";

export const BIDDING_OFFER_REQUEST_SEND = "BIDDING_OFFER_REQUEST_SEND";
export const BIDDING_OFFER_REQUEST_SUCCESS = "BIDDING_OFFER_REQUEST_SUCCESS";
export const BIDDING_OFFER_REQUEST_FAIL = "BIDDING_OFFER_REQUEST_FAIL";

export const PARTNER_OFFER_ACCEPT_REQUEST_SEND =
  "PARTNER_OFFER_ACCEPT_REQUEST_SEND";
export const PARTNER_OFFER_ACCEPT_SUCCESS = "PARTNER_OFFER_ACCEPT_SUCCESS";
export const PARTNER_OFFER_ACCEPT_FAIL = "PARTNER_OFFER_ACCEPT_FAIL";

export const BKASH_PAYMENT_REQUEST_DATA_ADD = "BKASH_PAYMENT_REQUEST_DATA_ADD";
export const COUPON_ADD = "COUPON_ADD";
export const DISCOUNT_ADD = "DISCOUNT_ADD";
export const ADD_RIDE_ID = "ADD_RIDE_ID";

export const GET_SYSTEM_CONFIGS_REQUEST_SEND =
  "GET_SYSTEM_CONFIGS_REQUEST_SEND";
export const GET_SYSTEM_CONFIGS_REQUEST_SUCCESS =
  "GET_SYSTEM_CONFIGS_REQUEST_SUCCESS";
export const GET_SYSTEM_CONFIGS_REQUEST_FAIL =
  "GET_SYSTEM_CONFIGS_REQUEST_FAIL";

export const GET_SINGLE_CONFIG_REQUEST_SEND = "GET_SINGLE_CONFIG_REQUEST_SEND";
export const GET_SINGLE_CONFIG_REQUEST_SUCCESS =
  "GET_SINGLE_CONFIG_REQUEST_SUCCESS";
export const GET_SINGLE_CONFIG_REQUEST_FAIL = "GET_SINGLE_CONFIG_REQUEST_FAIL";

export const NOTICE_LIST_REQUEST_SEND = "NOTICE_LIST_REQUEST_SEND";
export const NOTICE_LIST_REQUEST_SUCCESS = "NOTICE_LIST_REQUEST_SUCCESS";
export const NOTICE_LIST_REQUEST_FAIL = "NOTICE_LIST_REQUEST_FAIL";

export const ACTIVITY_LIST_REQUEST_SEND = "ACTIVITY_LIST_REQUEST_SEND";
export const ACTIVITY_LIST_REQUEST_SUCCESS = "ACTIVITY_LIST_REQUEST_SUCCESS";
export const ACTIVITY_LIST_REQUEST_FAIL = "ACTIVITY_LIST_REQUEST_FAIL";

export const UPDATE_QUICAR_POINT = "UPDATE_QUICAR_POINT";

export const GET_CANCEL_RIDE_CUT_CAL_SEND = "GET_CANCEL_RIDE_CUT_CAL_SEND";
export const GET_CANCEL_RIDE_CUT_CAL_SUCCESS =
  "GET_CANCEL_RIDE_CUT_CAL_SUCCESS";
export const GET_CANCEL_RIDE_CUT_CAL_FAIL = "GET_CANCEL_RIDE_CUT_CAL_FAIL";

export const PARTNER_REVIEW_SEND = "PARTNER_REVIEW_SEND";
export const PARTNER_REVIEW_SUCCESS = "PARTNER_REVIEW_SUCCESS";
export const PARTNER_REVIEW_FAIL = "PARTNER_REVIEW_FAIL";

export const WITHDRAW_REQUEST_SEND = "WITHDRAW_REQUEST_SEND";
export const WITHDRAW_REQUEST_SUCCESS = "WITHDRAW_REQUEST_SUCCESS";
export const WITHDRAW_REQUEST_FAIL = "WITHDRAW_REQUEST_FAIL";
