import { requestApi } from "../../network/httpRequest";
import { GET_PRIVACY_POLICY } from "../../network/urls";
import * as actionType from "../actionTypes";


export const getPolicy = (type) => async (dispatch, getState) => {
    try {

        dispatch({
            type: actionType.PRIVACY_REQUEST_SEND
        });

        const { data } = await requestApi().request(GET_PRIVACY_POLICY, {
            params: {
                type: type,
            }
        });

        if (data.status) {
            dispatch({
                type: actionType.PRIVACY_REQUEST_SUCCESS,
                payload: {
                    type:type,
                    policies:data.data.policies
                }
            });
        } else {
            dispatch({
                type: actionType.PRIVACY_REQUEST_FAIL,
                payload: data.error
            });
        }



    } catch (error) {
        dispatch({
            type: actionType.PRIVACY_REQUEST_FAIL,
            payload: error.message
        });
    }
};