import { useState, useEffect, useRef , lazy } from "react";
import { useSelector } from "react-redux";
import PartnerMessageView from "./PartnerMessageView";
import UserMessageView from "./UserMessageView";


const UserAndPartnerChat = () => {
  const { bidding, loading } = useSelector((state) => state.rideReducer);
  const chatWrapperRef = useRef(null);

  useEffect(() => scrollToBottom(),[bidding]);

  const scrollToBottom = () => {
    chatWrapperRef.current.scrollIntoView({ behavior: "smooth" }, true);
    chatWrapperRef.current.scrollBy(0, 100);
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          padding: "0px 10px 100px 10px",
        }}>
        {bidding.bidding_amounts.map((item, index, arr) => {
          return item.offerSendBy === "partner" ? (
            <PartnerMessageView index={index} key={index} item={item} length={bidding.bidding_amounts.length} biddingAmounts={arr}/>
          ) : (
            <UserMessageView index={index} key={index} item={item} length={bidding.bidding_amounts.length}/>
          );
        })}
      </div>
      <div ref={chatWrapperRef}></div>
    </div>
  );
};

export default UserAndPartnerChat;
