import React, { useRef, useState } from "react";
import { useEffect } from "react";
import "./style.css";
import styled from "styled-components";
import PageLayout from "../../components/layout/PageLayout";
import BalanceSummery from "../../components/BalanceSummery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FinalSummery from "../../components/FinalSummery";
import { requestApi } from "../../network/httpRequest";
import {
  APPLY_COUPON,
  BKASH_PAYMENT_CREATE,
  BKASH_PAYMENT_EXECUTE,
  CHECK_DISCOUNT,
  PAYMENT_WITH_QUICAR_POINT,
} from "../../network/urls";
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  addBiddingId,
  addBkashInit,
  addCoupon,
  addDiscount,
} from "../../redux/bkash/bkashAction";
import { getQuicarPointList } from "../../redux/point/pointAction";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { getSingleConfig } from "../../redux/home/homeAction";

// let paymentID , accessToken;

const AmountSummery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bkashButtonRef = useRef(null);

  const { bidding, loading, userPaymentConditions, offerAccepting } =
    useSelector((state) => state.rideReducer);

  // paymentID , accessToken
  const { discount, coupon, biddingId } = useSelector(
    (state) => state.bkashReducer
  );
  const { userBalance } = useSelector((state) => state.transection);
  const { systemConfig } = useSelector((state) => state.homeReducer);

  const [couponShow, setCouponShow] = useState(false);

  let paymentConfig = {
    createCheckoutURL: BKASH_PAYMENT_CREATE,
    executeCheckoutURL: BKASH_PAYMENT_EXECUTE,
  };

  const [amountId, setAmountId] = useState();
  const [advancePayment, setAdvancePayment] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponAmount, setCouponAmount] = useState(0);
  const [netPayable, setNetPayable] = useState(0);
  const [adjustQuicarPoint, setAdjustQuicarPoint] = useState(false);
  const [readOnlyCouponCode, setReadOnlyCouponCode] = useState(false);
  const [applingCouponCode, setApplyingCouponCode] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [couponInput, setCouponInput] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [bankCharge, setBankCharge] = useState(0);
  const [rentAmount, setRentAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(getQuicarPointList(true));
      dispatch(getSingleConfig("bkash_bank_charge_percentage"));
    }

    return () => (mounted = false);
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      let payableAmount = advancePayment - (discountAmount + couponAmount);
      setRentAmount(parseFloat(payableAmount?.toFixed(2)));

      let bankChargeAmount = 0;
      const bankChargePercent = systemConfig?.value;
      if (bankChargePercent) {
        bankChargeAmount =
          (payableAmount * parseFloat(bankChargePercent)) / 100;
        setBankCharge(bankChargeAmount);
        payableAmount += bankChargeAmount;
      }

      setNetPayable(parseFloat(payableAmount));
    }

    return () => (mounted = false);
  }, [advancePayment, discountAmount, couponAmount, systemConfig]);

  const clickCouponHave = (isShow) => {
    setCouponShow(isShow);
  };

  const clickPayButton = async () => {
    if (adjustQuicarPoint > 0 && adjustQuicarPoint == advancePayment) {
      setOpenConfirmModal(true);
    } else {
      let reConfigPaymentRequest = {
        amount: String(netPayable),
        intent: "sale",
        amountId: String(amountId),
      };
      await initBkash(amountId, netPayable);

      callReconfigure(reConfigPaymentRequest);
      const bKashButton = bkashButtonRef.current;
      if (bKashButton) {
        bKashButton.click();
      }
    }
  };

  function callReconfigure(val) {
    // eslint-disable-next-line no-undef
    bKash.reconfigure(val);
  }

  const getLastAmount = async (bidding_amounts) => {
    const partnerOffer = bidding_amounts.filter(
      (item) => item.offerSendBy === "partner"
    );
    const lastOffer = partnerOffer[partnerOffer.length - 1];
    return lastOffer;
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    if (bidding) {
      dispatch(addBiddingId(bidding?.id));
      callAPI();
    } else {
      navigate("/");
    }
  }, []);

  const callAPI = async () => {
    const advancePayment = getAdvancePayment();
    setAdvancePayment(advancePayment);
    const amountObj = await getLastAmount(bidding.bidding_amounts);
    setAmountId(amountObj.id);
    await checkDiscount(amountObj.id, advancePayment);
  };

  const checkDiscount = async (amountId_, advancePayment) => {
    const { data } = await requestApi().request(CHECK_DISCOUNT, {
      params: {
        amountId: amountId_,
      },
    });
    // setDiscountCheckLoading(false);

    if (data.status) {
      let discountFound = data["data"]["discountFound"];
      if (discountFound) {
        let discount_ = data.data["discount"];
        const discountAmount_ = (advancePayment * discount_["amount"]) / 100;

        // setDiscount(discount_);
        dispatch(addDiscount(discount_["id"]));
        setDiscountAmount(discountAmount_);

        // let netPayable_ = advancePayment - (couponAmount + discountAmount);
        // setNetPayable(netPayable_);
      }
    } else {
      alert(data["message"]);
    }
  };

  const initBkash = async (_amountId, nAmount) => {
    let paymentRequest = {
      amount: nAmount,
      intent: "sale",
      amountId: String(_amountId),
    };
    // eslint-disable-next-line no-undef
    bKash.init({
      paymentMode: "checkout",
      paymentRequest: paymentRequest,
      createRequest: function (request) {
        console.log("=> createRequest (request) :: ", request);
        fetch(
          paymentConfig.createCheckoutURL +
            "?amountId=" +
            request.amountId +
            "&netAmount=" +
            request.amount
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            console.log("data", data);

            if (data.status) {
              let obj = data.data;

              if (obj.paymentID != null) {
                localStorage.setItem("paymentID", obj.paymentID);
                localStorage.setItem("accessToken", data.token);

                dispatch(
                  addBkashInit({
                    ...obj,
                    token: data.token,
                  })
                );

                // setPaymentID(obj.paymentID);
                // setAccessToken(data.token);
                // eslint-disable-next-line no-undef
                bKash.create().onSuccess(obj);
              } else {
                console.log("error");
                // eslint-disable-next-line no-undef
                bKash.create().onError();
                alert(obj.errorMessage);
                // errorSend(obj.errorMessage);
              }
            } else {
              // eslint-disable-next-line no-undef
              bKash.create().onError();
              alert(data.error);
              // errorSend(data.error);
            }
          });
      },

      executeRequestOnAuthorization: function () {
        console.log("=> executeRequestOnAuthorization");
        console.log({ discount });
        console.log({ coupon });
        const paymentID = localStorage.getItem("paymentID");
        const accessToken = localStorage.getItem("accessToken");

        fetch(
          paymentConfig.executeCheckoutURL +
            "?paymentID=" +
            paymentID +
            "&token=" +
            accessToken +
            "&amountId=" +
            amountId +
            "&amount=" +
            netPayable +
            "&payableAmount=" +
            rentAmount +
            "&adjustAmount=" +
            adjustQuicarPoint +
            "&couponAmount=" +
            couponAmount +
            "&discountAmount=" +
            discountAmount +
            "&coupon=" +
            coupon +
            "&discount=" +
            discount
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            console.log("data get from executeCheckoutURL ::=>");
            console.log(JSON.stringify(data));
            if (data.status) {
              let { paymentID, trxID, errorMessage } = data.data;
              if (paymentID != null) {
                navigate(
                  `/payment-success?trxId=${trxID}&paymentId=${paymentID}&biddingId=${biddingId}`
                );
              } else {
                // eslint-disable-next-line no-undef
                bKash.execute().onError();

                setToast(true);
                setToastMessage(errorMessage);
              }
            } else {
              // eslint-disable-next-line no-undef
              bKash.execute().onError();
              setToast(true);
              setToastMessage(data.error);
            }
          });
      },
    });
  };

  const getAdvancePayment = () => {
    let distanceFromPickUpLocation = bidding["distanceFromPickUpLocation"];
    let distance = distanceFromPickUpLocation / 1000;
    let amount =
      bidding["bidding_amounts"][bidding["bidding_amounts"].length - 1];
    let biddingAmount = amount["biddingAmount"];
    const where = userPaymentConditions.find(
      (item) =>
        distance >= item["minKilometer"] && distance <= item["maxKilometer"]
    );
    if (where && where.percentage > 0) {
      return (biddingAmount * where.percentage) / 100;
    }
    return 0;
  };

  const applyCoupon = async () => {
    if (readOnlyCouponCode) {
      setReadOnlyCouponCode(false);
      setCouponInput("");
      setCouponAmount(0);
      dispatch(addCoupon(null));
      return;
    }

    if (!couponInput) {
      setToast(true);
      setToastMessage("Please enter coupon code");
      return;
    }

    setApplyingCouponCode(true);

    const { data } = await requestApi().request(APPLY_COUPON, {
      params: {
        couponCode: couponInput,
      },
    });

    setApplyingCouponCode(false);

    if (data.status) {
      let coupon_ = data.data["coupon"];
      dispatch(addCoupon(coupon_["id"]));

      let type = coupon_["type"];
      let maxAmount = coupon_["maxAmount"];

      if (type === "percentage") {
        let percentage = coupon_["amount"];
        let amount = (advancePayment * percentage) / 100;
        if (amount < maxAmount) {
          setCouponAmount(amount);
        }
      } else {
        let directAmount = coupon_["amount"];
        let amount = directAmount;
        if (amount < maxAmount) {
          setCouponAmount(amount);
        }
      }

      if (couponAmount > 0) {
        // show success message
      }
      setReadOnlyCouponCode(true);
    } else {
      setToast(true);
      setToastMessage(data.error);
      return;
    }
  };

  // ADJUST QUICAR POINT

  const handleAdjustQuicarPoint = (e) => {
    const { checked } = e.target;
    const amount = getAdvancePayment();
    if (checked) {
      // setAdvancePayment(0);

      const adjustPoints = userBalance >= amount ? amount : userBalance;
      const bkashPayAmount =
        adjustPoints === amount ? 0 : amount - adjustPoints + bankCharge;
      console.log(parseFloat(adjustPoints?.toFixed(2)));

      setAdjustQuicarPoint(parseFloat(adjustPoints?.toFixed(2)));
      setNetPayable(parseFloat(bkashPayAmount?.toFixed(2)));
    } else {
      setAdjustQuicarPoint(0);
      // setAdvancePayment(amount);
      setNetPayable(amount + bankCharge);
    }
  };

  // ADVANCE PAYMENT WITH QUICAR POINT

  const advancePaymentWithQuicarPoint = async () => {
    try {
      if (!amountId || !adjustQuicarPoint) {
        throw new Error("Invalid Request!. Try again.");
      }
      setIsLoading(true);
      const { data } = await requestApi().request(PAYMENT_WITH_QUICAR_POINT, {
        method: "POST",
        data: {
          amountId,
          amount: adjustQuicarPoint,
          quicarCharge: bankCharge,
          coupon,
          couponAmount,
          discountAmount,
          discount,
        },
      });

      if (!data?.status) {
        throw new Error("Payment Not Success!.Try Again.");
      }
      setToast(true);
      setToastMessage("Ride Accept SuccessFully!");
      const { trxID, paymentID } = data?.data;
      navigate(
        `/payment-success?trxId=${trxID}&paymentId=${paymentID}&biddingId=${biddingId}`
      );
    } catch (e) {
      console.log({ e });
      const errorMessage = e.message;
      setToast(true);
      setToastMessage(errorMessage);
      setIsLoading(false);
    }
  };

  return (
    <PageLayout
      title="Summery"
      appbar={true}
      appBgColor="#F79520"
      overflow="auto"
    >
      <div ref={bkashButtonRef} id="bKash_button"></div>

      <Snackbar
        open={toast}
        autoHideDuration={6000}
        onClose={() => setToast(false)}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={() => setToast(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>

      {bidding && (
        <Wrapper>
          <div
            style={{
              backgroundColor: "#f4f3f9",
              overflow: "auto",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 17 }} gutterBottom>
                    Quicar Points
                  </Typography>
                  <Typography variant="h5" component="div">
                    {(userBalance - adjustQuicarPoint)?.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </div>

            <BalanceSummery />

            <FinalSummery
              advancePayment={advancePayment}
              couponAmount={couponAmount}
              discountAmount={discountAmount}
              adjustQuicarPoint={adjustQuicarPoint}
              bankChargeAmount={bankCharge}
            />

            {!couponShow && (
              <div
                onClick={() => clickCouponHave(true)}
                style={{
                  display: "flex",
                  justifyContent: "right",
                  width: "100%",
                  paddingTop: "20px",
                  paddingRight: "30px",
                  cursor: "pointer",
                }}
              >
                <span style={{ textDecoration: "underline" }}>
                  Have any Coupon
                </span>
              </div>
            )}

            {couponShow && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    paddingTop: "10px",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Coupon"
                    variant="outlined"
                    value={couponInput}
                    onChange={(e) => setCouponInput(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    paddingTop: "10px",
                  }}
                >
                  <Button onClick={applyCoupon} variant="contained">
                    {readOnlyCouponCode
                      ? "Remove Coupon"
                      : applingCouponCode
                      ? "Applying..."
                      : "Apply"}
                  </Button>
                  <Button
                    onClick={() => clickCouponHave(false)}
                    variant="contained"
                    color="warning"
                    sx={{ marginLeft: 2 }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}

            {userBalance > 0 && (
              <div className="adjust_qucar_point_wrapper">
                <FormGroup>
                  <FormControlLabel
                    control={<Switch onChange={handleAdjustQuicarPoint} />}
                    label="Adjust Quicar Point"
                  />
                </FormGroup>
              </div>
            )}
          </div>

          {/* <div class="wrapper">
              <div id="loading"></div>
            </div> */}

          <div
            style={{
              position: "sticky",
              bottom: 0,
              width: "100%",
              padding: "",
              marginTop: "10px",
              boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.25)",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <div
              onClick={clickPayButton}
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#F79520",
                padding: "0px",
                borderRadius: "5px",
                color: "#fff",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                {netPayable > 0
                  ? `Pay ${netPayable.toFixed(2)} Tk Advance`
                  : `Pay By ${adjustQuicarPoint + bankCharge} Points `}
              </span>
            </div>
          </div>
        </Wrapper>
      )}

      <ConfirmationDialog
        open={openConfirmModal}
        handleClose={(e) => setOpenConfirmModal(false)}
        acceptedHandler={() => advancePaymentWithQuicarPoint()}
        title="Are you sure?"
        description={`Accept Bid With Quicar Point ${
          adjustQuicarPoint + bankCharge
        }`}
        isLoading={isLoading}
      />
    </PageLayout>
  );
};

const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 5px solid rgb(26 108 97 / 56%);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .adjust_qucar_point_wrapper {
    padding: 0px 10px;
  }
`;

export default AmountSummery;
