import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  notifications: [],
  notification: null,
  badge: 0,
  paginate: null,
  nextPage: 1,
  previousPage: 1,
  currentPage: 1,
  hasNextPage: true,
  hasPreviousPage: false,
  paging: [],
  pageSize: 10,
  error: null,
};

const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.NOTIFICATION_LIST_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.NOTIFICATION_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        badge: payload.badge,
        notifications: payload.start
          ? payload.notifications
          : [...state.notifications, ...payload.notifications],
        error: null,
        message: null,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        nextPage: payload.paginate.metadata.page.nextPage,
        previousPage: payload.paginate.metadata.page.previousPage,
      };
    case actionTypes.NOTIFICATION_SINGLE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        notification: payload,
        error: null,
        message: null,
        badge: state.badge > 0 ? state.badge - 1 : state.badge,
      };
    case actionTypes.NOTIFICATION_BADGE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        badge: payload,
        error: null,
        message: null,
      };

    case actionTypes.NOTIFICATION_LIST_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;
