import { requestApi } from "../../network/httpRequest";
import { ALL_ACTIVITY } from "../../network/urls";
import {
  ACTIVITY_LIST_REQUEST_FAIL,
  ACTIVITY_LIST_REQUEST_SEND,
  ACTIVITY_LIST_REQUEST_SUCCESS,
} from "../actionTypes";

export const getActivities = (isFirstPage) => async (dispatch, getState) => {
  const { nextPage, activities } = getState().activityReducer;

  if (activities.length > 0 && isFirstPage) {
    return;
  }

  try {
    dispatch({
      type: ACTIVITY_LIST_REQUEST_SEND,
    });

    const { data } = await requestApi().request(ALL_ACTIVITY, {
      params: {
        page: isFirstPage ? 1 : nextPage,
        pageSize: 10,
      },
    });
    console.log("activity", data);

    if (data.status) {
      dispatch({
        type: ACTIVITY_LIST_REQUEST_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: ACTIVITY_LIST_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTIVITY_LIST_REQUEST_FAIL,
      payload: error.message,
    });
  }
};
