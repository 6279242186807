
import * as actionTypes from '../actionTypes';


const initialState = {
    loading: false,
    paymentID: '',
    accessToken: '',
    rideId: '',
    coupon: null,
    discount : null,
    biddingId: null
}


const bkashReducer = (state = initialState, action) => {

    const { type, payload } = action

    switch (type) {
        case actionTypes.BKASH_PAYMENT_REQUEST_DATA_ADD:
            console.log(payload);
            return {
                ...state,
                paymentID: payload.paymentID,
                accessToken: payload.token,
            };

        case actionTypes.COUPON_ADD:
            return {
                ...state,
                coupon: payload
            }

        case actionTypes.DISCOUNT_ADD:
            return {
                ...state,
                discount: payload
            }
        case actionTypes.ADD_RIDE_ID:
            return {
                ...state,
                biddingId: payload
            }

        default:
            return state;
    }

}


export default bkashReducer;