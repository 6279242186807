import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button, Card, CardContent, CircularProgress } from "@mui/material";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";

import PageLayout from "../components/layout/PageLayout";
import { Toaster } from "react-hot-toast";
import { getActivities } from "../redux/activity/activityAction";
import HistoryIcon from "@mui/icons-material/History";
import { QuicarSmLogo } from "../components/QuicarLogo";
import moment from "moment";

const UserActivity = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, status, activities, hasNextPage } = useSelector(
    (state) => state.activityReducer
  );

  useEffect(() => {
    dispatch(getActivities(true));
  }, []);

  const loadMore = () => {
    dispatch(getActivities(false));
  };

  return (
    <PageLayout
      title="Activity"
      appbar={true}
      notificationBadge={false}
      appBgColor="#F79520"
      bgColor="white"
      iconColor={"white"}
      titleColor="white"
      badgeIconColor="white"
      elevation={5}
    >
      {/* <Toaster /> */}

      <Wrapper>
        {activities?.map(({ title, content, createdAt }) => (
          <Card
            style={{ backgroundColor: "#f1eee5c7", marginBottom: "10px" }}
            className="cursor-pointer "
          >
            <CardContent>
              <ItemWrapper>
                <div className="logo_wrapper">
                  <QuicarSmLogo />
                </div>
                <div className="content_wrapper">
                  <h5 className="title">{title}</h5>
                  <p className="details">{content}</p>
                  <span>{moment(createdAt).fromNow()}</span>
                </div>
              </ItemWrapper>
            </CardContent>
          </Card>
        ))}
      </Wrapper>
      {loading && (
        <div className="center-div">
          <CircularProgress size={25} />
        </div>
      )}

      {hasNextPage && !loading && (
        <div className="center_div">
          <Button variant="outlined" onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}

      {activities.length < 1 && !loading && (
        <div className="center_varticle">
          <HistoryIcon
            style={{ width: "50px", height: "50px", fill: "grey" }}
          ></HistoryIcon>
        </div>
      )}
    </PageLayout>
  );
};

const Wrapper = styled.div`
  margin: 15px 10px;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  .logo_wrapper {
    width: 40px;
  }
  .content_wrapper {
    margin-left: 5px;
    flex: 1;

    .title {
      font-size: 15px;
      padding-bottom: 4px;
    }
    .details {
      font-size: 15px;
      padding-bottom: 4px;
    }
  }
`;

export default UserActivity;
