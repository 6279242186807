import { requestApi } from "../../network/httpRequest";
import {
  NOTIFICATION,
  NOTIFICATION_BADGE,
  NOTIFICATION_SEEN,
} from "../../network/urls";
import * as actionType from "../actionTypes";

export const getNotificationList = (start) => async (dispatch, getState) => {
  try {
    const { notifications, nextPage, pageSize } = getState().notification;

    // if (start) {
    //   return;
    // }

    dispatch({
      type: actionType.NOTIFICATION_LIST_REQUEST_SEND,
    });

    const { data } = await requestApi().request(NOTIFICATION, {
      params: {
        page: start ? 1 : nextPage,
        pageSize: pageSize,
      },
    });

    if (data.status) {
      dispatch({
        type: actionType.NOTIFICATION_LIST_REQUEST_SUCCESS,
        payload: {
          start: start,
          notifications: data.data.notifications,
          badge: data.data.badge,
          paginate: data.data.paginate,
        },
      });
    } else {
      dispatch({
        type: actionType.NOTIFICATION_LIST_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.NOTIFICATION_LIST_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const getSingleNotification = (id) => async (dispatch, getState) => {
  try {
    const { notifications } = getState().notification;

    // check if notification is already have
    let notification = notifications.find(
      (notification) => notification.id == id
    );

    if (notification) {
      notification.isSeen = true;
      dispatch({
        type: actionType.NOTIFICATION_SINGLE_REQUEST_SUCCESS,
        payload: notification,
      });

      await requestApi().request(NOTIFICATION_SEEN, {
        params: {
          id: id,
        },
      });
    } else {
      dispatch({
        type: actionType.NOTIFICATION_LIST_REQUEST_SEND,
      });

      const { data } = await requestApi().request(NOTIFICATION + "/" + id);

      if (data.status) {
        dispatch({
          type: actionType.NOTIFICATION_SINGLE_REQUEST_SUCCESS,
          payload: data.data.notification,
        });
      } else {
        dispatch({
          type: actionType.NOTIFICATION_LIST_REQUEST_FAIL,
          payload: data.error,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: actionType.NOTIFICATION_LIST_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const getTotalNotificationBadge = () => async (dispatch, getState) => {
  try {
    const { data } = await requestApi().request(NOTIFICATION_BADGE);
    if (data.status) {
      dispatch({
        type: actionType.NOTIFICATION_BADGE_REQUEST_SUCCESS,
        payload: data.data.badge,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.NOTIFICATION_LIST_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const reduceNotificationBadge = () => async (dispatch, getState) => {
  const { badge } = getState().notification;
  if (badge > 0) {
    dispatch({
      type: actionType.NOTIFICATION_BADGE_REQUEST_SUCCESS,
      payload: badge - 1,
    });
  }
};
