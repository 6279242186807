import React, { useEffect, useState } from "react";
import styledComponent from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import HistoryIcon from "@mui/icons-material/History";

import {
  getQuicarPointList,
  pointWithdrawRequest,
} from "../redux/point/pointAction";
import TnxItem from "../components/TnxItem";
import PageLayout from "../components/layout/PageLayout";
import { signal } from "@preact/signals";
import { Textarea } from "@mui/joy";
import AlertMsg from "../components/AlertMsg";

const QuicarPoinPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    accounts,
    userBalance,
    loading,
    hasNextPage,
    status,
    message,
    error,
  } = useSelector((state) => state.transection);

  const [openModal, setOpenModal] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const withdrawData = signal({
    amount: 0,
    paymentReceivedNumber: "",
    userNote: "",
  });

  useEffect(() => {
    dispatch(getQuicarPointList(true));
  }, []);

  const loadMore = () => {
    dispatch(getQuicarPointList(false));
  };

  // WITHDRAW POINTS
  const changeWithdrawInput = (e) => {
    const { name, value } = e.target;

    withdrawData.value = { ...withdrawData.value, [name]: value };
  };

  const sendWithdrawRequest = () => {
    const { amount, paymentReceivedNumber, userNote } = withdrawData.value;
    console.log({ amount, paymentReceivedNumber });
    if (!amount || !paymentReceivedNumber) {
      setToast(true);
      setToastMessage("Invalid request!.");
      return;
    }

    if (amount > userBalance) {
      setToast(true);
      setToastMessage("Invalid amount!");
      return;
    }

    dispatch(pointWithdrawRequest(withdrawData.value));
  };

  useEffect(() => {
    let mounted = true;
    if (status) {
      if (mounted) {
        setOpenModal(false);

        withdrawData.value = {
          amount: 0,
          userNote: "",
          paymentReceivedNumber: null,
        };
      }

      setToast(true);
      setToastMessage(message);
    } else {
      if (error) {
        setToast(true);
        setToastMessage(error);
      }
    }

    return () => (mounted = false);
  }, [status]);

  return (
    <div>
      <PageLayout
        title="Quicar Point"
        appbar={true}
        appBgColor="#F79520"
        titleColor="white"
        iconColor="white"
      >
        <AlertMsg
          isOpen={toast}
          toastMessage={toastMessage}
          closeAlert={() => setToast(false)}
          type={status ? "success" : ""}
        />
        {/* balance show */}
        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          <WalletBalance>
            <span
              style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
            >
              {" "}
              Quicar Point{" "}
            </span>
            <Balance>{userBalance?.toFixed(2) || 0}</Balance>
            <span
              style={{
                fontSize: "11px",
                fontWeight: "400",
                color: "#969696",
              }}
            >
              {" "}
              1 Quicar Point = 1 BDT{" "}
            </span>
          </WalletBalance>
        </div>

        <WithdrawBtn>
          <Button
            variant="contained"
            color="warning"
            onClick={() => setOpenModal(!openModal)}
            disabled={userBalance < 5}
          >
            Withdraw
          </Button>
        </WithdrawBtn>

        {accounts.map((item, index) => {
          return <TnxItem index={index} item={item} key={index} />;
        })}

        {loading && (
          <div className="center_div">
            <CircularProgress size={25} />
          </div>
        )}

        {hasNextPage && !loading && (
          <div className="center_div">
            <Button variant="outlined" onClick={loadMore}>
              Load More
            </Button>
          </div>
        )}

        {accounts.length < 1 && !loading && (
          <div className="center_varticle">
            <HistoryIcon
              style={{ width: "50px", height: "50px", fill: "grey" }}
            ></HistoryIcon>
          </div>
        )}

        {/* WITHDRAW MODAL */}

        <Modal
          open={openModal}
          onClose={() => setOpenModal(!openModal)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography>Withdraw Amount</Typography>
            <hr />

            <div
              style={{
                marginTop: "20px",
              }}
            >
              <Box mb={3}>
                <Typography mb={1}>Bkash Wallet Number</Typography>
                <TextField
                  placeholder="Enter Your Bkash Number"
                  name="paymentReceivedNumber"
                  fullWidth
                  onChange={changeWithdrawInput}
                />
              </Box>

              <Box mb={2}>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Amount
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    label="Amount"
                    name="amount"
                    onChange={changeWithdrawInput}
                  />
                </FormControl>
              </Box>

              <Box mb={3}>
                <Typography mb={1}>Note</Typography>
                <Textarea
                  placeholder="Type in here…"
                  name="userNote"
                  minRows={2}
                  maxRows={4}
                  onChange={changeWithdrawInput}
                />
              </Box>

              <div>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ mr: 1 }}
                  onClick={sendWithdrawRequest}
                  disabled={loading || userBalance < 5}
                >
                  {loading ? "Sending.." : "Send"}
                </Button>
                <Button
                  variant="outline"
                  color="error"
                  onClick={() => setOpenModal(false)}
                >
                  Later
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </PageLayout>
    </div>
  );
};

const WalletBalance = styledComponent.div`
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background with gradiesnt horizontal
    background: linear-gradient(90deg, #919191 0%, #4D4D4D 100%);
    border-radius: 5px;
    padding: 10px 0;
    margin: 10px 0
    

    `;

const Balance = styledComponent.h1`
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    margin: 0;
    `;

const WithdrawBtn = styledComponent.div`

  text-align: center;
  padding: 10px 0px;

`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default QuicarPoinPage;
