import React from 'react'
import styled from 'styled-components'

const CenterDiv = ({children}) => <Wrapper>{children}</Wrapper>
const Wrapper = styled.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
`

export default CenterDiv