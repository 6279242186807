import React, { useEffect, useState } from "react";
import styledCompunent from "styled-components";
import PageLayout from "../../components/layout/PageLayout";
import { getSingleBid, partnerOfferAccept } from "../../redux/ride/rideAction";
import { useDispatch, useSelector } from "react-redux";
import PartnerInformation from "../../components/PartnerInformation";
import { useNavigate, useSearchParams } from "react-router-dom";
import DriverDetailsBottomNavigation from "../../components/DriverDetailsBottomNavigation";
import UserAndPartnerChat from "../../components/UserAndPartnerChat";
import BalanceSummery from "../../components/BalanceSummery";
import RideDetailsDetailView from "../../components/RIdeDetailsDetailView";
import ConfirmationDialog from "../../components/ConfirmationDialog";

const BiddingDetailsPage = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [isEpendable, setEpendable] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [acceptConfirmation, setAcceptConfirmation] = useState(false);
  const id = searchParams.get("biddingId");
  const dispatch = useDispatch();
  const { bidding, loading, userPaymentConditions, offerAccepting } =
    useSelector((state) => state.rideReducer);

  const { socket } = useSelector((state) => state.socketReducer);

  // bidding.status

  useEffect(() => {
    let mounted = true;
    if (id) {
      // api call
      if (mounted) {
        dispatch(getSingleBid(id));
      }
    }
    return () => (mounted = false);
  }, [id]);

  useEffect(() => {
    let mounted = true;
    if (socket) {
      if (mounted) {
        if (bidding) {
          socket.emit("join_bidding_chat", {
            room: bidding["ride"]["bookingId"],
          });
        }
        socket.on("bidding_offer_received", (data) => {
          dispatch(getSingleBid(id));
        });
      }
    }
    return () => (mounted = false);
  }, [socket]);

  const expendView = (e) => {
    setEpendable((value) => !value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const acceptedPartnerOffer = () => {
    if (
      bidding["bidding_amounts"][bidding["bidding_amounts"].length - 1][
        "offerSendBy"
      ] === "partner"
    ) {
      let distanceFromPickUpLocation = bidding["distanceFromPickUpLocation"];
      let distance = distanceFromPickUpLocation / 1000;
      const where = userPaymentConditions.find(
        (item) =>
          distance >= item["minKilometer"] && distance <= item["maxKilometer"]
      );
      if (where && where.percentage > 0) {
        bkashPayment(where);
      } else {
        setAcceptConfirmation(true);
      }
    }
  };

  const getLastBiddingAmount = () => {
    return bidding["bidding_amounts"][bidding["bidding_amounts"].length - 1];
  };

  const bkashPayment = (paymentCondition) => {
    let amount = getLastBiddingAmount();
    // bidding
    // navigate to amountSummery

    navigate("/ride-summery");
  };

  const confirmRide = () => {
    let amount = getLastBiddingAmount();
    dispatch(partnerOfferAccept(amount["id"]));
  };

  useEffect(() => {
    let mounted = true;
    if (!offerAccepting) {
      if (mounted) {
        setAcceptConfirmation(false);
      }
    }
    return () => (mounted = false);
  }, [offerAccepting]);

  const getAcceptedTitle = () => {
    let distanceFromPickUpLocation = bidding["distanceFromPickUpLocation"];
    let distance = distanceFromPickUpLocation / 1000;
    let amount =
      bidding["bidding_amounts"][bidding["bidding_amounts"].length - 1];
    let biddingAmount = amount["biddingAmount"];
    const where = userPaymentConditions.find(
      (item) =>
        distance >= item["minKilometer"] && distance <= item["maxKilometer"]
    );
    if (where && where.percentage > 0)
      return `With Advance payment ${
        (biddingAmount * where.percentage) / 100
      } tk`;
    return "Without advance payment";
  };

  const partnerOfferButton = () => {
    const lastBidAmount =
      bidding.bidding_amounts[bidding.bidding_amounts.length - 1];

    return (
      <div
        onClick={(e) => acceptedPartnerOffer()}
        style={{
          width: "100%",
          height: "100%",
          // backgroundColor: "#016064",
          backgroundColor: "#F79520",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: "bold" }}>
          Accept TK. {lastBidAmount.biddingAmount}
        </p>
        <p style={{ fontSize: "12px", color: "black" }}>{getAcceptedTitle()}</p>
      </div>
    );
  };

  const userOfferButton = (lastAmount) => {
    if (lastAmount["acceptedPrice"]) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#016064",
            // backgroundColor: "#8b8987",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
            {" "}
            Comfirm Partner
          </p>
        </div>
      );
    }

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          // backgroundColor: "#016064",
          backgroundColor: "#8b8987",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: "bold" }}>
          Wait for partner response..
        </p>
      </div>
    );
  };

  return (
    <PageLayout
      title="Bidding Details"
      appbar={true}
      // bgColor="#eef3f9"
      // appBgColor="#f4f3f9"
      overflow="hidden"
    >
      {bidding && (
        <div
          style={{
            backgroundColor: "#f4f3f9",
            overflow: "auto",
          }}
        >
          <div
            style={{
              overflow: "auto",
            }}
          >
            {loading && (
              <div className="loading">
                <div className="loader">Loading....</div>
              </div>
            )}

            <div>
              <PartnerInformation
                img={bidding?.partner?.img}
                name={bidding?.partner?.name}
                phone={
                  bidding?.status === "accepted" ? bidding?.partner?.phone : ""
                }
              />

              <BalanceSummery bidding_amounts={bidding.bidding_amounts} />

              <RideDetailsDetailView
                isEpendable={isEpendable}
                bidding={bidding}
                handleClickOpen={handleClickOpen}
                expendView={expendView}
              />

              <UserAndPartnerChat />
            </div>
          </div>

          <DriverDetailsBottomNavigation
            open={open}
            handleClose={handleClose}
            driver={bidding.driver}
          />
          <ConfirmationDialog
            open={acceptConfirmation}
            handleClose={(e) => setAcceptConfirmation(false)}
            acceptedHandler={(e) => confirmRide()}
            title="Are you sure?"
            description={`You are going to accept this offer. TK. ${
              bidding.bidding_amounts[bidding.bidding_amounts.length - 1]
                .biddingAmount
            }`}
          />
        </div>
      )}

      {bidding && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            padding: "",
            boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.25)",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {bidding.bidding_amounts[bidding.bidding_amounts.length - 1]
            .offerSendBy === "partner"
            ? partnerOfferButton()
            : userOfferButton(bidding.bidding_amounts.at(-1))}
        </div>
      )}
    </PageLayout>
  );
};

const Wrapper = styledCompunent.div`
  display: flex;
  justify-content: space-between;
`;

const BalanceWrapper = styledCompunent.div`
  display: flex;
  justify-content: space-between;
`;

export default BiddingDetailsPage;
