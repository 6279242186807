import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const ConfirmationDialog = ({
  open,
  handleClose,
  acceptedHandler,
  title,
  description,
  isLoading = false,
}) => {
  const { offerAccepting } = useSelector((state) => state.rideReducer);

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          No
        </Button>

        {offerAccepting || isLoading ? (
          <CircularProgress style={{ color: "yellow" }} />
        ) : (
          <Button onClick={acceptedHandler} autoFocus>
            Yes
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
