import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import sc from "styled-components";
import success_shap_svg from "../ride/success_shap_svg.svg";
import { Button } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { purple, orange } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
const theme = createTheme({
  palette: {
    primary: orange,
    secondary: orange,
  },
});

const CheckPriceList = styled(Button)({
  boxShadow: "none",
  textTransform: "uppercase",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid #ffffff",
  lineHeight: 1.5,
  backgroundColor: "#EE9A39",
  borderColor: "#ffffff",
  color: "white",
  "&:hover": {
    backgroundColor: "#EE9A39",
    borderColor: "#ffffff",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#EE9A39",
    borderColor: "#ffffff",
  },
});
const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [trxId, setTrxId] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [biddingId, setBiddingId] = useState("");

  useEffect(() => {
    const trxId = searchParams.get("trxId");
    const paymentId = searchParams.get("paymentId");
    const biddingId = searchParams.get("biddingId");
    let mounted = true;
    if (trxId && paymentId && biddingId) {
      if (mounted) {
        setTrxId(trxId);
        setBiddingId(biddingId);
        setPaymentId(paymentId);
      }
    }
    return () => (mounted = false);
  }, []);

  return (
    <Layout>
      <div
        style={{
          backgroundColor: "#EE9A39",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: "50px",
          }}
        >
          <img src={success_shap_svg} style={{ paddingBottom: "30px" }} />

          <Title>Payment Success</Title>

          <InfoWrapper>
            <span className="info">Transaction ID :</span>
            <span className="info">{trxId ?? "N/A"}</span>
          </InfoWrapper>

          <InfoWrapper>
            <span className="info">Payment ID :</span>
            <span className="info">{paymentId ?? "N/A"}</span>
          </InfoWrapper>

          <CheckPriceList
            variant="outlined"
            color="success"
            onClick={() => {
              navigate("/main/home", {
                replace: true,
              });
            }}
            style={{ marginTop: "20px" }}
          >
            Go Home
          </CheckPriceList>
        </div>
      </div>
    </Layout>
  );
};

const Title = sc.p`

font-size:22px;
text-transform: uppercase;
font-weight:bold;
color:#ffffff;
margin-bottom: 10px;


`;

const InfoWrapper = sc.div`

    text-align: center;
    padding: 10px;

    .info{
    font-size: 20px;
    font-weight: bold;
    color:#ffffff;
    :first-child{
      margin-right: 10px;
    }
    }
`;

export default PaymentSuccessPage;
