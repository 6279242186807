import { Edit, LocationOn } from '@mui/icons-material'
import { Avatar, Box, Card, Chip, Divider, IconButton, Stack, Switch, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import moment from 'moment/moment'
import React from 'react'

const PartnerCard = ({ item: { partner } }) => {
    return (
        <div>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Avatar
                    variant="rounded"
                    src={partner.img}
                    sx={{ width: 56, height: 56 }}
                />
                <div style={{flex:'1'}}>
                    <div style={{ paddingLeft: '10px' }}>
                        <Typography fontWeight={700}>{partner.name}</Typography>
                        {partner?.dob ? <Typography fontWeight={500}>{moment(partner?.dob).format("MMM Do YYYY")}</Typography> : <div></div>}
                    </div>
                </div>
                {/* <Avatar
                    variant="rounded"
                    src={partner.img}
                    sx={{ width: 56, height: 56 }}
                /> */}
            </Box>
            <Divider />
        </div>
    )
}

export default PartnerCard