import React from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import PageLayout from "./layout/PageLayout";
import { Avatar, Divider } from "@mui/material";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import { Toolbar } from "@mui/material";
import { AppBar } from "@mui/material";

const DriverDetailsBottomNavigation = ({ open, handleClose, driver }) => {
  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <PageLayout>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Driver Details
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem>
            <Avatar alt={""} src={driver?.img} sx={{ width: 60, height: 60 }} />
            <ListItemText
              primary={driver.name}
              secondary={
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {driver.phone}
                  </Typography>
                  <div>
                    <button
                      onClick={(e) => window.open(`tel:${driver?.phone}`)}
                    >
                      Call Now
                    </button>
                  </div>
                </div>
              }
              style={{ paddingLeft: "10px" }}
            />
          </ListItem>

          <Divider />
          <ListItem>
            <ListItemText primary="Address" secondary={driver?.address} />
          </ListItem>
        </List>
      </PageLayout>
    </Dialog>
  );
};

export default DriverDetailsBottomNavigation;
