import { requestApi } from "../../network/httpRequest";
import { GET_TURIRIALS } from "../../network/urls";
import * as actionType from "../actionTypes";

export const getTutorials = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionType.TUTORIAL_REQUEST_SEND,
    });

    const { data } = await requestApi().request(GET_TURIRIALS);

    if (data.status) {
      dispatch({
        type: actionType.TUTORIAL_REQUEST_SUCCESS,
        payload: data.data.tutorials,
      });
    } else {
      dispatch({
        type: actionType.TUTORIAL_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.TUTORIAL_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const getSingleTutorial = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionType.TUTORIAL_REQUEST_SEND,
    });

    const { data } = await requestApi().request(GET_TURIRIALS + `/${id}`);

    if (data.status) {
      dispatch({
        type: actionType.SINGLE_TUTORIAL_REQUEST_SUCCESS,
        payload: data.data.tutorial,
      });
    } else {
      dispatch({
        type: actionType.TUTORIAL_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.TUTORIAL_REQUEST_FAIL,
      payload: error.message,
    });
  }
};
