import { Card, Divider } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import styledCompunent from "styled-components";
import Tk from "./Tk";

const BalanceSummery = () => {
  const { bidding, loading, userPaymentConditions } = useSelector(
    (state) => state.rideReducer
  );

  const [amount, setAmount] = React.useState(0);
  const [advanceAmount, setAdvanceAmount] = React.useState(0);
  const [netAmount, setNetAmount] = React.useState(0);

  useEffect(() => {
    setNetAmount(amount - advanceAmount);
  }, [amount, advanceAmount]);

  useEffect(() => {
    const partnerOffer = bidding.bidding_amounts.filter(
      (item) => item.offerSendBy === "partner"
    );
    const lastOffer = partnerOffer[partnerOffer.length - 1];

    setAmount(lastOffer.biddingAmount);
    getAcceptedTitle();
  }, [bidding]);

  const getAcceptedTitle = () => {
    let distanceFromPickUpLocation = bidding["distanceFromPickUpLocation"];
    let distance = distanceFromPickUpLocation / 1000;
    let amount =
      bidding["bidding_amounts"][bidding["bidding_amounts"].length - 1];
    let biddingAmount = amount["biddingAmount"];
    const where = userPaymentConditions.find(
      (item) =>
        distance >= item["minKilometer"] && distance <= item["maxKilometer"]
    );
    if (where && where.percentage > 0) {
      setAdvanceAmount((biddingAmount * where.percentage) / 100);
    }
  };

  return (
    <div>
      <Card
        style={{
          backgroundColor: "#ffffff",
          padding: "10px",
          margin: "10px",
          borderRadius: "10px",
        }}
      >
        <BalanceWrapper>
          <span>Total Rent</span>
          <span>
            {amount} <Tk />{" "}
          </span>
        </BalanceWrapper>
        <BalanceWrapper>
          <span>Advance Payment(Online/Quicar point) </span>
          <span>
            {advanceAmount} <Tk />
          </span>
        </BalanceWrapper>
        {/* <hr /> */}
        <Divider />
        <BalanceWrapper>
          <span>Due (Pay to Driver)</span>
          <span>
            {netAmount} <Tk />
          </span>
        </BalanceWrapper>
      </Card>
    </div>
  );
};

const BalanceWrapper = styledCompunent.div`
  display: flex;
  justify-content: space-between;
`;

export default BalanceSummery;
