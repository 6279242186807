import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  error: null,
  userPrivacyPolicy: null,
  userAppAboutUs: null,
  userPaymentPolicy: null,
  userRideCancellationPolicy: null,
  userPaymentRefundPolicy: null,
  contactUs: null,
};

const policyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.PRIVACY_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.PRIVACY_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        [payload.type]: payload.policies,
      };

    case actionTypes.PRIVACY_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case actionTypes.PRIVACY_REQUEST_TYPE_CHANGE:
      return {
        ...state,
        loading: false,
        type: payload,
      };

    default:
      return state;
  }
};

export default policyReducer;
