import { Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import PageLayout from "../components/layout/PageLayout";

import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getPolicy } from "../redux/policy/policyAction";
import styled from "styled-components";
import { Textarea } from "@mui/joy";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";

const Contactus = () => {
  const dispatch = useDispatch();
  const { contactUs } = useSelector((state) => state.policyReducer);

  const [message, setMessage] = useState("");

  useEffect(() => {
    dispatch(getPolicy("contactUs"));
    setMessage("");
  }, []);

  const sendWhatsapp = () => {
    const { whatsapp } = JSON.parse(contactUs);
    const whatsappUrl = `https://wa.me/${whatsapp}?text=${encodeURIComponent(
      message
    )}`;

    if (isWhatsAppInstalled) {
      console.log(isWhatsAppInstalled);
      window.open(whatsappUrl, "_blank");
    } else {
      alert("Please install whats app");
    }
  };

  const sendMail = () => {
    const { gmail } = JSON.parse(contactUs);

    const mailtoLink = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${gmail}&su=${encodeURIComponent(
      "Quicar"
    )}&body=${encodeURIComponent(message)}`;
    window.open(mailtoLink, "_blank");
  };

  const voiceCall = () => {
    const { contactNumber } = JSON.parse(contactUs);
    window.open(`tel:${contactNumber}`, "_blank");
  };

  const isWhatsAppInstalled = () => {
    return /WhatsApp/.test(navigator.userAgent);
  };

  return (
    <PageLayout
      title="Contact Us"
      appbar={true}
      notificationBadge={false}
      appBgColor="#F79520"
      bgColor="white"
      iconColor={"white"}
      titleColor="white"
      badgeIconColor="white"
      elevation={5}
    >
      <Toaster />

      <Wrapper>
        <div className="message_wrapper">
          <Textarea
            color="success"
            minRows={3}
            placeholder="Enter Message"
            size="lg"
            variant="soft"
            name="message"
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="button_wrapper">
            <Button
              variant="contained"
              color="success"
              onClick={sendWhatsapp}
              disabled={!message}
              startIcon={<WhatsAppIcon />}
            >
              Whatsapp
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="gmail_btn"
              onClick={sendMail}
              disabled={!message}
              startIcon={<EmailIcon />}
            >
              Email
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={voiceCall}
              // disabled={!message}
              startIcon={<AddIcCallIcon />}
            >
              Call
            </Button>
          </div>
        </div>
      </Wrapper>
    </PageLayout>
  );
};

const Wrapper = styled.div`
  padding: 20px 10px;

  .message_wrapper {
    margin-top: 20px;
    text-align: center;

    .button_wrapper {
      margin-top: 15px;

      .gmail_btn {
        margin: 0px 5px;
      }
    }
  }
`;

export default Contactus;
