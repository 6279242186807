import { requestApi } from "../../network/httpRequest";
import { FAVORITE_PARTNER } from "../../network/urls";
import * as actionType from "../actionTypes";


export const getFavoritePartner = (start) => async (dispatch, getState) => {
    try {

        const { hasNextPage, nextPage, type, pageSize } = getState().favoritesPartners;


        dispatch({
            type: actionType.FAVORITE_PARTNER_REQUEST_SEND
        });

        const { data } = await requestApi().request(FAVORITE_PARTNER, {
            params: {
                type: type,
                page: start ? 1 : nextPage,
                pageSize: pageSize
            }
        });

        console.log("data",data)

        if (data.status) {
            dispatch({
                type: actionType.FAVORITE_PARTNER_REQUEST_SUCCESS,
                payload: {
                    start: start,
                    list: data.data.list,
                    paginate: data.data.paginate
                }
            });
        } else {
            dispatch({
                type: actionType.RIDE_LIST_REQUEST_FAIL,
                payload: data.error
            });
        }



    } catch (error) {
        dispatch({
            type: actionType.FAVORITE_PARTNER_REQUEST_FAIL,
            payload: error.message
        });
    }
};