import React from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTutorial } from "../../redux/tutorial/tutorialAction";
import CenterDiv from "../../components/CenterDiv";
import PageLayout from "../../components/layout/PageLayout";
import PageLoader from "../../components/PageLoader";

const TutorialDetailPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { tutorial, loading } = useSelector((state) => state.tutorial);

  useEffect(() => {
    dispatch(getSingleTutorial(id));
  }, [id]);

  return (
    <PageLayout
      title="Tutorials"
      appbar={true}
      appBgColor="#F79520"
      titleColor="white"
      iconColor="white"
    >
      {loading ? (
        <PageLoader />
      ) : (
        <div>
          <iframe
            id="player"
            type="text/html"
            style={{ width: "100%", height: "310px" }}
            src={`http://www.youtube.com/embed/${tutorial?.youtubeVideoId}`}
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      )}
    </PageLayout>
  );
};

export default TutorialDetailPage;
