import React, { useEffect, Suspense } from "react";
import GlobalWrapper from "../../../components/GlobalWrapper";
import { useDispatch } from "react-redux";
import { homeAction } from "../../../redux/home/homeAction";
import { clearAllCarRentalData } from "../../../redux/action/carRentalAction";
import { getTotalNotificationBadge } from "../../../redux/notification/notificationAction";

const ImageSlider = React.lazy(() =>
  import("../../../components/imageSlider/ImageSlider")
);
const HomePageCategory = React.lazy(() =>
  import("../../../components/homepaveCategory/HomePageCategory")
);
const HomeOfferShow = React.lazy(() =>
  import("../../../components/homeOfferShow/HomeOfferShow")
);
const HomeFragment = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(homeAction());
    dispatch(getTotalNotificationBadge());
  }, []);

  return (
    <GlobalWrapper>
      <Suspense fallback={<div>...</div>}>
        <ImageSlider />
      </Suspense>

      <Suspense fallback={<div>...</div>}>
        <HomePageCategory />
      </Suspense>
    </GlobalWrapper>
  );
};

export default HomeFragment;
