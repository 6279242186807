import {
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_REQUEST_FAIL,
  GET_USER_INFO_REQUEST_FAIL__,
  GET_USER_INFO_REQUEST_SUCCESS,
  GET_USER_INFO_REQUEST_SUCCESS__,
  GET_USER_INFO_REQUEST__,
  UPDATE_PROFILE_INFORMATION,
  USER_UPDATE_REDUX,
  USER_LOGIN,
  USER_LOGOUT
} from "../actionTypes";

const initialState = {
  accessToken: localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  loading: false,
  error: "",
  idLoading: false,
};

const usereducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_USER_INFO_REQUEST_SUCCESS:
      const { user, accessToken } = payload;

      localStorage.setItem("user", JSON.stringify(user));

      return {
        ...state,
        loading: false,
        user: user,
        accessToken: accessToken,
        error: null,
      };

    case GET_USER_INFO_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case USER_LOGIN:
      localStorage.setItem("accessToken", payload.accessToken);
      localStorage.setItem("user", JSON.stringify({ ...payload.user }));
      return {
        ...state,
        accessToken: payload.accessToken,
        user: payload.user,
      };

    case USER_UPDATE_REDUX:
      var storeUser = JSON.parse(localStorage.getItem("user"));

      storeUser = {
        ...storeUser,
        ...payload,
      };

      localStorage.setItem("user", JSON.stringify(storeUser));

      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };

    case USER_LOGOUT:
      return {
        ...state,
        accessToken: null,
        user: null,
      };

    case GET_USER_INFO_REQUEST__:
      //   localStorage.setItem("accessToken", payload.accessToken);
      //   localStorage.setItem("user", JSON.stringify({ ...payload.user }));
      return {
        ...state,
        idLoading: true,
      };

    case GET_USER_INFO_REQUEST_SUCCESS__:
      const { balance, user:userInfo } = payload;
      console.log("payload",payload)
      localStorage.setItem("user", JSON.stringify({ ...userInfo }));
      return {
        ...state,
        user: userInfo,
        idLoading: false,
      };

    case GET_USER_INFO_REQUEST_FAIL__:
      return {
        ...state,
        idLoading: false,
        error: payload,
      };
    
    case UPDATE_PROFILE_INFORMATION:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
      

    default:
      return state;
  }
};

export default usereducer;
