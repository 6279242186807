// const svgFillColor = "#d8d8d8";

export const profile = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g id="Group_6422" data-name="Group 6422" transform="translate(-35 -264)">
        <g id="Group_6421" data-name="Group 6421">
            <g id="Group_6420" data-name="Group 6420">
                <g id="Group_6419" data-name="Group 6419">
                    <g id="Group_6418" data-name="Group 6418">
                        <g id="Group_6417" data-name="Group 6417">
                            <g id="Group_6416" data-name="Group 6416">
                                <g id="Group_6415" data-name="Group 6415">
                                    <g id="Group_6414" data-name="Group 6414">
                                        <g id="Group_6413" data-name="Group 6413">
                                            <g id="Group_6412" data-name="Group 6412">
                                                <circle id="Ellipse_902" data-name="Ellipse 902" cx="15" cy="15" r="15" transform="translate(35 264)" fill="#9d9d9d" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="profile-user" transform="translate(41 269.999)">
                    <path id="Path_13947" data-name="Path 13947" d="M8.892,0a8.892,8.892,0,1,0,8.892,8.892A8.892,8.892,0,0,0,8.892,0Zm0,2.659A2.941,2.941,0,1,1,5.951,5.6,2.941,2.941,0,0,1,8.892,2.66Zm0,12.8a6.526,6.526,0,0,1-4.25-1.567,1.253,1.253,0,0,1-.44-.953A2.966,2.966,0,0,1,7.181,9.975H10.6A2.962,2.962,0,0,1,13.58,12.94a1.25,1.25,0,0,1-.439.952A6.524,6.524,0,0,1,8.89,15.46Z" transform="translate(0 0)" fill="#fff" />
                </g>
            </g>
        </g>
    </g>
</svg>


export const quicarPoint = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
<g id="Group_6423" data-name="Group 6423" transform="translate(-35 -319)">
  <circle id="Ellipse_903" data-name="Ellipse 903" cx="15" cy="15" r="15" transform="translate(35 319)" fill="#9d9d9d"/>
  <path id="Icon_awesome-wallet" data-name="Icon awesome-wallet" d="M13.527,5.066H2.346a.469.469,0,1,1,0-.939H13.609a.469.469,0,0,0,.469-.469A1.408,1.408,0,0,0,12.671,2.25H1.877A1.877,1.877,0,0,0,0,4.127v9.386A1.877,1.877,0,0,0,1.877,15.39h11.65a1.452,1.452,0,0,0,1.49-1.408V6.474A1.452,1.452,0,0,0,13.527,5.066Zm-1.326,6.1a.939.939,0,1,1,.939-.939A.939.939,0,0,1,12.2,11.166Z" transform="translate(42 324.75)" fill="#fff"/>
</g>
</svg>



export const statement = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
<g id="Group_6426" data-name="Group 6426" transform="translate(-35 -380)">
  <g id="Group_6425" data-name="Group 6425">
    <g id="Group_6424" data-name="Group 6424">
      <g id="Group_6063" data-name="Group 6063">
        <circle id="Ellipse_904" data-name="Ellipse 904" cx="15" cy="15" r="15" transform="translate(35 380)" fill="#9d9d9d"/>
        <g id="Group_6060" data-name="Group 6060" transform="translate(-930.08 176.266)">
          <path id="Path_22759" data-name="Path 22759" d="M983.538,209.412a.951.951,0,0,1,.7.671,3.551,3.551,0,0,1,.053.422c.136,0,.279-.006.421,0a5.779,5.779,0,0,1,.957.071,1.957,1.957,0,0,1,1.508,1.885c.009.443,0,.886,0,1.329q-.006,6.071-.011,12.142a1.966,1.966,0,0,1-1.143,1.913,2,2,0,0,1-.839.2q-5.116.02-10.232.006A1.986,1.986,0,0,1,972.979,226q0-4.405.006-8.811,0-2.294.006-4.587a1.946,1.946,0,0,1,1.176-1.908,2.881,2.881,0,0,1,.879-.187,7.8,7.8,0,0,1,.842-.006c0-.075.006-.134.01-.193a.915.915,0,0,1,.61-.848c.038-.015.074-.034.111-.052Zm-7.667,2.185h-.754a.929.929,0,0,0-1.036,1.032q-.007,6.672-.011,13.343a.912.912,0,0,0,.984.989h10.032a1.245,1.245,0,0,0,.253-.019.925.925,0,0,0,.737-1q.006-6.471.011-12.943c0-.176,0-.352,0-.528a.841.841,0,0,0-.635-.817,9.6,9.6,0,0,0-1.163-.069c-.01.063-.014.08-.015.1a.923.923,0,0,1-1.054,1.011H980.2q-1.675,0-3.35,0a.894.894,0,0,1-.893-.615A3.673,3.673,0,0,1,975.871,211.6Zm7.294-.007v-1.076H977v1.076Z" transform="translate(0)" fill="#fff"/>
          <path id="Path_22760" data-name="Path 22760" d="M1114.038,487.507h-1.873a.55.55,0,1,1,0-1.092h3.763a.549.549,0,1,1,0,1.092Q1114.983,487.508,1114.038,487.507Z" transform="translate(-131.829 -263.531)" fill="#fff"/>
          <path id="Path_22761" data-name="Path 22761" d="M1114.039,337.772h-1.873a.55.55,0,1,1,0-1.092h3.747a.549.549,0,1,1,0,1.092C1115.288,337.773,1114.664,337.772,1114.039,337.772Z" transform="translate(-131.829 -121.079)" fill="#fff"/>
          <path id="Path_22762" data-name="Path 22762" d="M1114.039,412.639h-1.873a.55.55,0,1,1,0-1.092h3.747a.549.549,0,1,1,0,1.091C1115.287,412.64,1114.663,412.639,1114.039,412.639Z" transform="translate(-131.829 -192.305)" fill="#fff"/>
          <path id="Path_22763" data-name="Path 22763" d="M1025.033,396.093c.438-.44.847-.853,1.258-1.265a.531.531,0,0,1,.569-.14.544.544,0,0,1,.212.9c-.542.547-1.085,1.093-1.634,1.633a.53.53,0,0,1-.782.008c-.234-.22-.461-.448-.682-.681a.544.544,0,1,1,.769-.77C1024.841,395.869,1024.925,395.974,1025.033,396.093Z" transform="translate(-48.362 -176.236)" fill="#fff"/>
          <path id="Path_22764" data-name="Path 22764" d="M1024.98,471.007c.439-.44.843-.842,1.243-1.247a.571.571,0,0,1,.451-.2.519.519,0,0,1,.471.319.493.493,0,0,1-.085.576q-.838.859-1.7,1.7a.5.5,0,0,1-.711,0c-.259-.237-.509-.486-.746-.745a.531.531,0,0,1,.026-.727.54.54,0,0,1,.742-.031A3.54,3.54,0,0,1,1024.98,471.007Z" transform="translate(-48.324 -247.499)" fill="#fff"/>
          <path id="Path_22765" data-name="Path 22765" d="M1025.041,315.533c.438-.438.841-.84,1.242-1.245a.552.552,0,0,1,.578-.168.542.542,0,0,1,.212.919c-.4.412-.813.816-1.221,1.223-.129.129-.255.26-.387.385a.543.543,0,0,1-.821-.006q-.326-.317-.643-.643a.544.544,0,1,1,.771-.768C1024.863,315.318,1024.941,315.42,1025.041,315.533Z" transform="translate(-48.378 -99.593)" fill="#fff"/>
        </g>
      </g>
    </g>
  </g>
</g>
</svg>



export const tutorial = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
<g id="Group_6427" data-name="Group 6427" transform="translate(-35 -435)">
  <g id="Group_6064" data-name="Group 6064">
    <circle id="Ellipse_905" data-name="Ellipse 905" cx="15" cy="15" r="15" transform="translate(35 435)" fill="#9d9d9d"/>
    <g id="video-tutorial" transform="translate(41 440)">
      <path id="Path_14364" data-name="Path 14364" d="M11.134,18.828H7.615A2.914,2.914,0,0,0,8.2,17.07v-.586h2.344v.586a2.914,2.914,0,0,0,.587,1.758Zm6.444-5.859H15.234V4.688h2.344ZM13.234,3.516H11.719V2ZM4.687,1.172h5.859V4.688h3.516v8.281H4.687ZM3.515,4.688v8.281H1.172V4.688ZM1.172,14.141H17.578v1.172H1.172ZM18.75,16.484V3.516H14.891L11.375,0H3.515V3.516H0V16.484H7.031v.586a1.76,1.76,0,0,1-1.758,1.758H4.687V20h9.375V18.828h-.586a1.76,1.76,0,0,1-1.758-1.758v-.586Zm0,0" fill="#fff"/>
      <path id="Path_14365" data-name="Path 14365" d="M180,114.85l5.158-3.439L180,107.972Zm1.172-4.688,1.873,1.249-1.873,1.249Zm0,0" transform="translate(-172.969 -103.755)" fill="#fff"/>
    </g>
  </g>
</g>
</svg>



export const coupon = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
<g id="Group_6429" data-name="Group 6429" transform="translate(-35 -495)">
  <g id="Group_6428" data-name="Group 6428">
    <g id="Group_6065" data-name="Group 6065">
      <circle id="Ellipse_906" data-name="Ellipse 906" cx="15" cy="15" r="15" transform="translate(35 495)" fill="#9d9d9d"/>
      <g id="coupon" transform="translate(39 399.605)">
        <g id="Group_1810" data-name="Group 1810" transform="translate(0 104.209)">
          <g id="Group_1809" data-name="Group 1809" transform="translate(0 0)">
            <path id="Path_14357" data-name="Path 14357" d="M20.271,104.209H.977a.978.978,0,0,0-.977.977v2.928a.8.8,0,0,0,.373.68,2,2,0,0,1,.958,1.714,2,2,0,0,1-.958,1.714A.8.8,0,0,0,0,112.9v2.928a.978.978,0,0,0,.977.977H20.271a.978.978,0,0,0,.977-.977V105.186A.978.978,0,0,0,20.271,104.209Zm.354,11.622a.354.354,0,0,1-.354.354H4.947v-.021a.311.311,0,0,0-.622,0v.021H.977a.354.354,0,0,1-.354-.354V112.9a.176.176,0,0,1,.078-.151,2.623,2.623,0,0,0,1.253-2.244A2.623,2.623,0,0,0,.7,108.265a.176.176,0,0,1-.078-.151v-2.928a.354.354,0,0,1,.354-.354H4.325v.021a.311.311,0,1,0,.622,0v-.021H20.271a.354.354,0,0,1,.354.354Z" transform="translate(0 -104.209)" fill="#fff" stroke="#fff" strokeWidth="0.3"/>
          </g>
        </g>
        <g id="Group_1812" data-name="Group 1812" transform="translate(4.325 109.864)">
          <g id="Group_1811" data-name="Group 1811">
            <path id="Path_14358" data-name="Path 14358" d="M104.521,240.485a.311.311,0,0,0-.311.311v.665a.311.311,0,0,0,.622,0V240.8A.311.311,0,0,0,104.521,240.485Z" transform="translate(-104.21 -240.485)" fill="#fff" stroke="#fff" strokeWidth="0.3"/>
          </g>
        </g>
        <g id="Group_1814" data-name="Group 1814" transform="translate(4.325 105.872)">
          <g id="Group_1813" data-name="Group 1813" transform="translate(0 0)">
            <path id="Path_14359" data-name="Path 14359" d="M104.521,144.292a.311.311,0,0,0-.311.311v.665a.311.311,0,0,0,.622,0V144.6A.311.311,0,0,0,104.521,144.292Z" transform="translate(-104.21 -144.292)" fill="#fff" stroke="#fff" strokeWidth="0.3"/>
          </g>
        </g>
        <g id="Group_1816" data-name="Group 1816" transform="translate(4.325 107.868)">
          <g id="Group_1815" data-name="Group 1815" transform="translate(0 0)">
            <path id="Path_14360" data-name="Path 14360" d="M104.521,192.387a.311.311,0,0,0-.311.311v.665a.311.311,0,0,0,.622,0V192.7A.311.311,0,0,0,104.521,192.387Z" transform="translate(-104.21 -192.387)" fill="#fff" stroke="#fff" strokeWidth="0.3"/>
          </g>
        </g>
        <g id="Group_1818" data-name="Group 1818" transform="translate(4.325 111.86)">
          <g id="Group_1817" data-name="Group 1817" transform="translate(0 0)">
            <path id="Path_14361" data-name="Path 14361" d="M104.521,288.581a.311.311,0,0,0-.311.311v.665a.311.311,0,0,0,.622,0v-.665A.311.311,0,0,0,104.521,288.581Z" transform="translate(-104.21 -288.581)" fill="#fff" stroke="#fff" strokeWidth="0.3"/>
          </g>
        </g>
        <g id="Group_1820" data-name="Group 1820" transform="translate(4.325 113.856)">
          <g id="Group_1819" data-name="Group 1819" transform="translate(0 0)">
            <path id="Path_14362" data-name="Path 14362" d="M104.521,336.677a.311.311,0,0,0-.311.311v.665a.311.311,0,0,0,.622,0v-.665A.311.311,0,0,0,104.521,336.677Z" transform="translate(-104.21 -336.677)" fill="#fff" stroke="#fff" strokeWidth="0.3"/>
          </g>
        </g>
        <g id="Group_1822" data-name="Group 1822" transform="translate(8.649 106.538)">
          <g id="Group_1821" data-name="Group 1821">
            <path id="Path_14363" data-name="Path 14363" d="M216.441,163.221a.805.805,0,0,0-.715-.557l-1.836-.125-.686-1.708a.809.809,0,0,0-1.5,0l-.686,1.708-1.836.125a.809.809,0,0,0-.464,1.428l1.412,1.18-.449,1.785a.809.809,0,0,0,1.215.883l1.559-.979,1.559.979a.809.809,0,0,0,1.215-.883l-.449-1.785,1.412-1.18A.805.805,0,0,0,216.441,163.221Zm-.65.393-1.562,1.305a.311.311,0,0,0-.1.315l.5,1.974a.187.187,0,0,1-.28.2l-1.724-1.083a.311.311,0,0,0-.331,0l-1.724,1.083a.187.187,0,0,1-.28-.2l.5-1.974a.311.311,0,0,0-.1-.315l-1.562-1.305a.187.187,0,0,1,.107-.329l2.031-.138a.311.311,0,0,0,.268-.195l.759-1.889a.187.187,0,0,1,.346,0l.759,1.889a.311.311,0,0,0,.268.195l2.031.138a.187.187,0,0,1,.107.329Z" transform="translate(-208.425 -160.324)" fill="#fff" stroke="#fff" strokeWidth="0.3"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</g>
</svg>




export const love = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
<g id="Group_6066" data-name="Group 6066" transform="translate(-35 -548)">
  <circle id="Ellipse_907" data-name="Ellipse 907" cx="15" cy="15" r="15" transform="translate(35 548)" fill="#9d9d9d"/>
  <path id="Icon_material-favorite-border" data-name="Icon material-favorite-border" d="M14.1,4.5a4.586,4.586,0,0,0-3.446,1.6A4.586,4.586,0,0,0,7.212,4.5,4.171,4.171,0,0,0,3,8.712c0,2.895,2.6,5.253,6.547,8.837l1.11,1,1.11-1.011c3.944-3.576,6.547-5.935,6.547-8.829A4.171,4.171,0,0,0,14.1,4.5ZM10.734,16.408l-.077.077-.077-.077c-3.645-3.3-6.05-5.483-6.05-7.7a2.617,2.617,0,0,1,2.68-2.68A2.994,2.994,0,0,1,9.945,7.839h1.432A2.975,2.975,0,0,1,14.1,6.032a2.617,2.617,0,0,1,2.68,2.68C16.784,10.925,14.379,13.107,10.734,16.408Z" transform="translate(39 553.5)" fill="#fff"/>
</g>
</svg>



export const lock = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
<circle id="Ellipse_908" data-name="Ellipse 908" cx="15" cy="15" r="15" fill="#9d9d9d"/>
<path id="padlock" d="M13.53,6.017h-.5V4.011a4.011,4.011,0,0,0-8.023,0V6.017H4.5A1.506,1.506,0,0,0,3,7.521v7.02a1.506,1.506,0,0,0,1.5,1.5H13.53a1.506,1.506,0,0,0,1.5-1.5V7.521A1.506,1.506,0,0,0,13.53,6.017ZM6.343,4.011a2.674,2.674,0,0,1,5.349,0V6.017H6.343ZM9.686,11.18V12.7a.669.669,0,0,1-1.337,0V11.18a1.337,1.337,0,1,1,1.337,0Z" transform="translate(6 7)" fill="#fff"/>
</svg>

export const logout = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
<g id="Group_6070" data-name="Group 6070" transform="translate(-35 -783)">
  <circle id="Ellipse_911" data-name="Ellipse 911" cx="15" cy="15" r="15" transform="translate(35 783)" fill="#9d9d9d"/>
  <path id="Icon_open-account-logout" data-name="Icon open-account-logout" d="M5.631,0V1.877H13.14v9.386H5.631V13.14h9.386V0ZM3.754,3.754,0,6.57,3.754,9.386V7.509h7.509V5.631H3.754Z" transform="translate(42 791)" fill="#fff"/>
</g>
</svg>