import React from "react";
import { Badge, Box } from "@mui/material";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import { Toolbar } from "@mui/material";
import { AppBar } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
const AppbarSimple = ({
  title,
  rideBidding,
  notificationBadge = false,
  appBgColor = "white",
  iconColor = "black",
  titleColor = "black",
  elevation = 0,
  badgeIconColor = "black",
}) => {
  const navigate = useNavigate();

  const { badge } = useSelector((state) => state.notification);

  // function goBack() {
  //     // navigate("/posts", {
  //     //   replace: true,
  //     // });
  //     navigate(-1);
  //   }

  return (
    <AppBar
      sx={{ position: "relative" }}
      style={
        appBgColor
          ? { background: appBgColor, padding: "0px" }
          : { background: "white", padding: "0px" }
      }
      elevation={elevation}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            if (rideBidding == true) {
              navigate("/main/ride?type=send");
            } else {
              navigate(-1);
            }
          }}
          // onClick={() => navigate(-1)}
          aria-label="close"
        >
          <ArrowBack style={{ color: iconColor }} />
        </IconButton>
        <Typography
          sx={{ ml: 2, flex: 2, color: titleColor }}
          variant="h6"
          component="div"
        >
          {title || "Title"}
        </Typography>
        {notificationBadge && <Box sx={{ flexGrow: 1 }} />}

        <Box sx={{ display: { xs: "flex", md: "flex" } }}>
          {notificationBadge && (
            <Badge badgeContent={badge > 0 ? badge : 0} color="error">
              <NotificationsIcon style={{ color: badgeIconColor }} />
            </Badge>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppbarSimple;
