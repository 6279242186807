import * as React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import PageLayout from "../components/layout/PageLayout";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  getUserInformation,
  setUpdateInformation,
} from "../redux/user/userAction";
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

import toast, { Toaster } from "react-hot-toast";
import styledComponent from "styled-components";
import { requestApi } from "../network/httpRequest";
import { USER_PROFILE_UPDATE } from "../network/urls";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const ProfileSettingPage = () => {
  const dispatch = useDispatch();
  const [editable, setEditable] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const [profile, setProfile] = React.useState({
    name: "",
    email: null,
    phoneNumber: "",
    dob: null,
    myReferral: "",
    referralByCode: "",
    img: "",
  });
  const [email, setEmail] = React.useState("");

  const { user } = useSelector((state) => state.usereducer);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    dispatch(getUserInformation());
  }, []);

  React.useEffect(() => {
    setProfile(user);
    setEmail(user.email);
  }, [user]);

  function copyToClipboard(refferalCode) {
    navigator.clipboard.writeText(refferalCode);
    toast("Copied to clipboard", {
      duration: 1000,
      position: "top-right",

      // Styling
      style: {},
      className: "",

      // Custom Icon
      icon: "👏",

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },

      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  }

  const styleInput = {
    width: "100%",
    marginBottom: "15px",
    color: "black",
    padding: "15px",
  };

  const emailChange = (e) => {
    setEditable(true);
    setProfile({ ...profile, email: e.target.value });
  };

  const submit = async () => {
    setLoading(true);

    const res = await requestApi().request(USER_PROFILE_UPDATE, {
      method: "POST",
      data: profile,
    });

    setLoading(false);

    if (res) {
      if (res.data.status) {
        setEditable(false);
        toast.success("Profile updated successfully");
        dispatch(setUpdateInformation(profile));
      }
    }
  };

  return (
    <PageLayout
      title="Profile Setting"
      appbar={true}
      appBgColor="#F79520"
      titleColor="white"
      iconColor="white"
    >
      {/* <ToastContainer /> */}

      <Wrapper>
        <Toaster />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "30px",
          }}
        >
          <Stack direction="row" spacing={2}>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar
                alt={profile.name}
                src={profile.img}
                sx={{ width: 60, height: 60 }}
              />
            </StyledBadge>
          </Stack>
        </div>
        <div style={{ paddingTop: "30px" }}>
          <Box padding={2}>
            <input
              ref={inputRef}
              id="need__disabled__color__change"
              style={styleInput}
              label={"Name"}
              value={profile.name}
              variant="outlined"
              disabled={profile.name ? true : false}
            />

            <input
              disabled
              id="component-helper"
              value={profile.phoneNumber}
              style={styleInput}
              onChange={() => {}}
              label="Phone Number"
              type="text"
              variant="outlined"
              aria-describedby="component-helper-text"
            />

            <input
              style={styleInput}
              label="Email"
              variant="outlined"
              onChange={emailChange}
              value={profile.email}
              disabled={email ? true : false}
              placeholder="Email"
            />

            {/*  date of birth */}
            {/* give me a date of birth field */}
            {/*  date of birth */}

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <MobileDatePicker
                  disabled={user.dob ? true : false}
                  style={{ width: "100%" }}
                  label="Date of Birth"
                  value={profile.dob || null}
                  onChange={(newValue) => {
                    setProfile({ ...profile, dob: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  // minDateTime={new Date()}
                />
              </Stack>
            </LocalizationProvider>

            <div
              style={{
                position: "relative",
                marginTop: "15px",
              }}
            >
              <input
                disabled
                style={{
                  width: "100%",
                  marginBottom: "5px",
                  padding: "15px",
                }}
                label="Referral ID "
                variant="outlined"
                value={"Referral ID - " + profile.myReferral || ""}
              />

              <Button
                style={{ position: "absolute", right: "5px", top: "6px" }}
                variant="contained"
                color="warning"
                onClick={() => copyToClipboard(profile.myReferral)}
              >
                Copy
              </Button>
            </div>

            {/* copy my referral code */}
          </Box>

          {editable ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "10px",
              }}
            >
              <Button onClick={submit} variant="contained">
                {" "}
                {isLoading ? "loading" : "Submit"}
              </Button>
            </Box>
          ) : null}
        </div>
      </Wrapper>
    </PageLayout>
  );
};

const Wrapper = styledComponent.div`


`;

export default ProfileSettingPage;
