import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const GlobalWrapper = ({ children }) => {

    const navigate = useNavigate();
    const { accessToken, user } = useSelector(state => state.usereducer);

    useEffect(() => {
        if (!accessToken || !user) {
            navigate('/login')
        }
        if (accessToken && user && !user.name) {
            navigate('/login')
            // navigate('/register')
        }
    }, [accessToken, user]);

    return <>
        {children}
    </>
}

export default GlobalWrapper;
