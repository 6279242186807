import * as actionTypes from '../actionTypes';
const initialState = {
    loading: false,
    error: null,
    list:[],
    tutorial: null
}
const tutorialReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case actionTypes.TUTORIAL_REQUEST_SEND:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.TUTORIAL_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                list: payload,
            };
        
        case actionTypes.SINGLE_TUTORIAL_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                tutorial: payload,
            };


        
        case actionTypes.TUTORIAL_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            };
        default:
            return state;
    }

}
export default tutorialReducer;