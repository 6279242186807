import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  accounts: [],
  account: null,
  badge: 0,
  paginate: null,
  nextPage: 1,
  previousPage: 1,
  currentPage: 1,
  hasNextPage: true,
  hasPreviousPage: false,
  paging: [],
  pageSize: 10,
  error: null,
  userBalance: 0,
  status: false,
  message: null,
};

const getTnxReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.TNX_LIST_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        error: null,
        message: null,
        status: false,
      };
    case actionTypes.TNX_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        badge: payload.badge,
        userBalance: payload.userBalance,
        accounts: payload.start
          ? payload.accounts
          : [...state.accounts, ...payload.accounts],
        error: null,
        message: null,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        nextPage: payload.paginate.metadata.page.nextPage,
        previousPage: payload.paginate.metadata.page.previousPage,
      };
    case actionTypes.TNX_SINGLE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        account: payload,
        error: null,
        message: null,
        badge: state.badge > 0 ? state.badge - 1 : state.badge,
      };

    case actionTypes.TNX_LIST_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case actionTypes.WITHDRAW_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        error: null,
        message: null,
        status: false,
      };

    case actionTypes.WITHDRAW_REQUEST_SUCCESS:
      const { after } = payload;
      return {
        ...state,
        loading: false,
        message: "Withdraw Request sent successfully",
        userBalance: after,
        status: true,
      };

    case actionTypes.WITHDRAW_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default getTnxReducer;
