import { requestApi } from "../../network/httpRequest";
import { USER_PROFILE } from "../../network/urls";
import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_UPDATE_REDUX,
} from "../actionTypes";

import * as actionType from "../actionTypes";

export const log_in = (user, accessToken) => async (dispatch) => {
  dispatch({
    type: USER_LOGIN,
    payload: { accessToken, user },
  });
};

export const update_user = (user) => async (dispatch) => {
  dispatch({
    type: USER_UPDATE_REDUX,
    payload: user,
  });
};
export const log_out = () => async (dispatch) => {
  console.log("logout")
  localStorage.removeItem("accessToken");
  localStorage.removeItem("user");

  dispatch({
    type: USER_LOGOUT,
  });
};


export const getUserInformation = (start) => async (dispatch, getState) => {
  try {
   
    console.log("calll")


    dispatch({
      type: actionType.GET_USER_INFO_REQUEST__,
    });

    const { data } = await requestApi().request(USER_PROFILE);

    console.log("data",data)

    if (data.status) {
      dispatch({
        type: actionType.GET_USER_INFO_REQUEST_SUCCESS__,
        payload: {
          balance: data.data.userBalance,
          user: data.data.user,
        },
      });
    } else {
      dispatch({
        type: actionType.GET_USER_INFO_REQUEST_FAIL__,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.GET_USER_INFO_REQUEST_FAIL__,
      payload: error.message,
    });
  }
};


export const setUpdateInformation = (data) => async (dispatch, getState) => {
  dispatch({
    type: actionType.UPDATE_PROFILE_INFORMATION,
    payload: data,
  });
}