import { requestApi } from "../../network/httpRequest";
import { GET_TRANSACTION_LIST, WITHDRAW_POINT } from "../../network/urls";
import * as actionType from "../actionTypes";

export const getQuicarPointList = (start) => async (dispatch, getState) => {
  try {
    const { accounts, nextPage, pageSize } = getState().transection;

    // if (accounts.length > 0 && start) {
    //   return;
    // }

    dispatch({
      type: actionType.TNX_LIST_REQUEST_SEND,
    });

    const { data } = await requestApi().request(GET_TRANSACTION_LIST, {
      params: {
        page: start ? 1 : nextPage,
        pageSize: pageSize,
      },
    });

    if (data.status) {
      dispatch({
        type: actionType.TNX_LIST_REQUEST_SUCCESS,
        payload: {
          start: start,
          accounts: data.data.accounts,
          userBalance: data.data.userBalance,
          paginate: data.data.paginate,
        },
      });
    } else {
      dispatch({
        type: actionType.TNX_LIST_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.TNX_LIST_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const getSingleNotification = (id) => async (dispatch, getState) => {
  try {
    const { accounts } = getState().notification;

    // check if notification is already have
    let account = accounts.find((item) => item.id == id);

    if (account) {
      dispatch({
        type: actionType.TNX_SINGLE_REQUEST_SUCCESS,
        payload: account,
      });
    } else {
      dispatch({
        type: actionType.TNX_LIST_REQUEST_SEND,
      });

      const { data } = await requestApi().request(
        GET_TRANSACTION_LIST + "/" + id
      );

      if (data.status) {
        dispatch({
          type: actionType.TNX_SINGLE_REQUEST_SUCCESS,
          payload: data.data.account,
        });
      } else {
        dispatch({
          type: actionType.TNX_LIST_REQUEST_FAIL,
          payload: data.error,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: actionType.TNX_LIST_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const pointWithdrawRequest = (values) => async (dispatch, getState) => {
  console.log({ values });
  try {
    dispatch({
      type: actionType.WITHDRAW_REQUEST_SEND,
    });

    const { data } = await requestApi().request(WITHDRAW_POINT, {
      method: "POST",
      data: values,
    });

    if (!data.status) {
      throw new Error(data.messsage || "Invalid Data");
    }

    const { accounts } = data.data;

    dispatch({
      type: actionType.WITHDRAW_REQUEST_SUCCESS,
      payload: accounts,
    });
  } catch (error) {
    dispatch({
      type: actionType.WITHDRAW_REQUEST_FAIL,
      payload: error.message,
    });
  }
};
