export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGIN = "USER_LOGIN";

export const MESSAGE_REQUEST = "MESSAGE_REQUEST";
export const MESSAGE_REQUEST_SUCCESS = "MESSAGE_REQUEST_SUCCESS";
export const MESSAGE_REQUEST_FAIL = "MESSAGE_REQUEST_FAIL";
export const MESSAGE_REQUEST_REFRESH_SHOW = "MESSAGE_REQUEST_REFRESH_SHOW";
export const MESSAGE_REQUEST_SUCCESS_REFRESH =
  "MESSAGE_REQUEST_SUCCESS_REFRESH";

export const SIDEBAR_SHOW = "SIDEBAR_SHOW";
export const SIDEBAR_HIDE = "SIDEBAR_HIDE";

export const SIDEBAR_VISIBLE = "SIDEBAR_VISIBLE";
export const SIDEBAR_GONE = "SIDEBAR_GONE";
export const CURRENT_PATH = "CURRENT_PATH";

export const GET_USER_LIST = "GET_USER_LIST";
export const SET_USER_LIST = "SET_USER_LIST";

export const GET_ADMIN_INFO = "GET_ADMIN_INFO";
export const GET_ADMIN_INFO_REQUEST = "GET_ADMIN_INFO_REQUEST";
export const GET_ADMIN_INFO_REQUEST_FAIL = "GET_ADMIN_INFO_REQUEST_FAIL";
export const GET_ADMIN_INFO_REQUEST_SUCCESS = "GET_ADMIN_INFO_REQUEST_SUCCESS";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_REQUEST_SUCCESS = "USER_LIST_REQUEST_SUCCESS";
export const USER_LIST_REQUEST_FAIL = "USER_LIST_REQUEST_FAIL";

export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_REQUEST_SUCCESS = "USER_INFO_REQUEST_SUCCESS";
export const USER_INFO_REQUEST_FAIL = "USER_INFO_REQUEST_FAIL";

export const CAR_RENTAL_REQUEST = "CAR_RENTAL_REQUEST";
export const CAR_RENTAL_REQUEST_SUCCESS = "CAR_RENTAL_REQUEST_SUCCESS";
export const CAR_RENTAL_REQUEST_FAIL = "CAR_RENTAL_REQUEST_FAIL";

export const RIDE_LIST_REQUEST = "RIDE_LIST_REQUEST";
export const RIDE_LIST_REQUEST_SUCCESS_PENDING =
  "RIDE_LIST_REQUEST_SUCCESS_PENDING";
export const RIDE_LIST_REQUEST_SUCCESS_WAITING_FOR_BID =
  "RIDE_LIST_REQUEST_SUCCESS_WAITING_FOR_BID";
export const RIDE_LIST_REQUEST_SUCCESS_UPCOMING =
  "RIDE_LIST_REQUEST_SUCCESS_UPCOMING";
export const RIDE_LIST_REQUEST_SUCCESS_WAITING_FOR_DRIVER =
  "RIDE_LIST_REQUEST_SUCCESS_WAITING_FOR_DRIVER";
export const RIDE_LIST_REQUEST_SUCCESS_UN_ACCEPTED =
  "RIDE_LIST_REQUEST_SUCCESS_UN_ACCEPTED";
export const RIDE_LIST_REQUEST_SUCCESS_COMPLETED =
  "RIDE_LIST_REQUEST_SUCCESS_COMPLETED";
export const RIDE_LIST_REQUEST_SUCCESS_CANCELED =
  "RIDE_LIST_REQUEST_SUCCESS_CANCELED";
export const RIDE_LIST_REQUEST_FAIL = "RIDE_LIST_REQUEST_FAIL";
