import React, { useState, useEffect } from "react";
import { Card, Divider } from "@mui/material";
import styledCompunent from "styled-components";
import Tk from "./Tk";
const FinalSummery = ({
  advancePayment,
  couponAmount,
  discountAmount,
  adjustQuicarPoint,
  bankChargeAmount,
}) => {
  const [netPayment, setNetPayment] = useState(0);

  useEffect(() => {
    setNetPayment(
      advancePayment - (couponAmount + discountAmount) + bankChargeAmount
    );
  }, [couponAmount, discountAmount, advancePayment, bankChargeAmount]);

  return (
    <div>
      <div style={{ paddingLeft: "20px" }}>
        <span>Final Summery</span>
      </div>

      <Card
        style={{
          backgroundColor: "#ffffff",
          padding: "10px",
          margin: "10px",
          borderRadius: "10px",
        }}
      >
        <BalanceWrapper>
          <span>Payable Amount</span>
          <span>
            {advancePayment} <Tk />{" "}
          </span>
        </BalanceWrapper>
        {adjustQuicarPoint > 0 && (
          <BalanceWrapper>
            <span>Adjust Quicar Point</span>
            <span>
              {adjustQuicarPoint} <Tk />
            </span>
          </BalanceWrapper>
        )}
        <BalanceWrapper>
          <span>Discount </span>
          <span>
            {discountAmount} <Tk />
          </span>
        </BalanceWrapper>
        <BalanceWrapper>
          <span>Coupon Discount </span>
          <span>
            {couponAmount} <Tk />
          </span>
        </BalanceWrapper>
        <BalanceWrapper>
          <span>Quicar Charge(Non Refundable)</span>
          <span>
            {bankChargeAmount} <Tk />
          </span>
        </BalanceWrapper>
        {/* <hr /> */}
        <Divider />
        <BalanceWrapper>
          <span>Total Amount</span>
          <span>
            {netPayment} <Tk />
          </span>
        </BalanceWrapper>
      </Card>
    </div>
  );
};
const BalanceWrapper = styledCompunent.div`
  display: flex;
  justify-content: space-between;
`;
export default FinalSummery;
